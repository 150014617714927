import axios from 'axios'

const state = () => ({
  url: null,
  userSaldo: 0,
  client: null,
  company_name: null,
  vendor_id: null,
  idHomecare: null
})

const getters = {
  getUrl: (state, getters, rootState) => {
    return state.url
  },
  getUserSaldo: (state) => {
    return state.userSaldo
  },
  getClient: (state, getters, rootState) => {
    return state.client
  },
  getCompanyName: (state, getters, rootState) => {
    return state.company_name
  },
  getVendorId: (state, getters, rootState) => {
    return state.vendor_id
  },
  getHomecareId: (state) => {
    return state.idHomecare
  }
}

const actions = {
  loadClient ({ commit, state }, data) {
    setTimeout(function () {
      axios.get(data.url + '/api/v1/vendor/client?page=1&limit=1000')
        .then((v) => {
          if (v.data.data.data.length > 0) {
            commit('setClient', v.data.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }, 1000)
  }
}

const mutations = {
  setUrl (state, data) {
    state.url = data
  },
  setUserSaldo (state, data) {
    state.userSaldo = data
  },
  setClient (state, data) {
    state.client = data
  },
  setCompanyName (state, data) {
    state.company_name = data
  },
  setVendorId (state, data) {
    state.vendor_id = data
  },
  setHomecareId (state, data) {
    state.idHomecare = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
