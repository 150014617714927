<template>
  <div>
    <div class="columns mt-2">
      <div class="column">
        <b-button @click="tambahOnClick()" type="is-success" icon-left="plus">Tambah Role</b-button>
        <b-button @click="bulkDelete()" icon-left="delete" type="is-danger" style="margin-left: 5px;" :disabled="!hasSelected"/>
      </div>
    </div>
    <div>
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="id" label="#" v-slot="props">
          <b-checkbox v-model="props.row.isSelect" />
        </b-table-column>
        <b-table-column field="name" label="Nama" v-slot="props">
          <div> {{ props.row.name }} </div>
        </b-table-column>
        <b-table-column field="jumlahUser" label="Jumlah User" v-slot="props">
          {{ props.row.total_user }}
        </b-table-column>
      </b-table>
    </div>

    <!-- Modal Tambah Role -->
    <b-modal
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      v-model="permissIsShow">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Tambah Role</p>
          <button type="button" class="delete" @click="permissIsShow = false"/>
        </header>
        <section class="modal-card-body">
          <b-field label="Nama Role">
            <b-input v-model="role" placeholder="finance" required></b-input>
          </b-field>
          <b-field label="Permission" style="margin-top: 20px;">
            <b-button @click="pilihPermissOnClick()" expanded type="is-success" label="Pilih Permission" />
          </b-field>
          <table class="table" style="width: 100%;">
            <thead>
              <tr>
                <td></td>
                <td class="has-text-weight-bold">Name Permission</td>
                <td class="has-text-weight-bold">URL</td>
              </tr>
            </thead>
            <tbody v-if="listPermissSelected !== null">
              <tr v-for="(v, i) in listPermissSelected" :key="i">
                <td> </td>
                <td>{{ v.permission_name }}</td>
                <td>{{ v.controller_path }}</td>
                <td><b-button @click="removeCurrentPermiss(i)" icon-left="delete" size="is-small" type="is-danger" outlined /></td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="saveDataPermiss()" type="is-success" label="Simpan"/>
        </footer>
      </div>
    </b-modal>

    <!-- Modal Add Permission in Role -->
    <b-modal
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      v-model="modalAddPermiss">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pilih Permission</p>
          <button type="button" class="delete" @click="modalAddPermiss = false"/>
        </header>
        <section class="modal-card-body">
          <table class="table table-container">
            <thead>
              <tr>
                <td></td>
                <td>Nama Permission</td>
                <td>URL</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, i) in permiss" :key="i">
                <td><b-checkbox v-model="v.isSelect"/></td>
                <td>{{ v.permission_name }}</td>
                <td>{{ v.controller_path }}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="simpanPermissOnClick()" type="is-success" label="Simpan"/>
        </footer>
      </div>
    </b-modal>

    <!-- <b-modal
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    v-model="detailRoleModal"
    >
      <div style="width: 650px;" class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Detail Permission</p>
          <button type="button" class="delete" @click="modalAddPermiss = false"/>
        </header>
        <section class="modal-card-body">
          <table class="table" style="width: 100%;">
            <thead>
              <tr>
                <td></td>
                <td>Nama Permission</td>
                <td>URL</td>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </section>
      </div>
    </b-modal> -->

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanJabatan',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    hasSelected () {
      return this.paginate.data.find(o => o.isSelect === true)
    }
  },
  components: {},
  watch: {},
  methods: {
    // setDetail () {
    //   this.detailRoleModal = true
    // },
    clearInput () {
      this.role = null
      this.listPermissSelected = []
    },
    setFormat () {},
    onPageChange () {},
    tambahOnClick () {
      this.permissIsShow = true
      this.currentPermiss = null
    },
    pilihPermissOnClick () {
      this.modalAddPermiss = true
      this.listPermissSelected = []
      this.permiss.forEach((row) => {
        row.isSelect = false
      })
    },
    loadData () {
      axios.get(this.url + '/api/v1/vendor/role?page=' + this.paginate.page + '&limit=' + this.paginate.perPage)
        .then((cb) => {
          this.paginate.data = cb.data.data
          this.paginate.total = (cb.data.total_page * this.paginate.perPage)
          this.paginate.page = cb.data.current_page
          this.paginate.loading = false
        })
    },
    loadPermission () {
      axios.get(this.url + '/api/v1/vendor/permission?page=1&limit=100')
        .then((data) => {
          this.permiss = []
          data.data.data.forEach((row) => {
            const _row = JSON.parse(JSON.stringify(row))
            _row.isSelect = false
            this.permiss.push(_row)
          })
        })
    },
    simpanPermissOnClick () {
      this.permiss.forEach((row) => {
        if (row.isSelect) {
          const _row = JSON.parse(JSON.stringify(row))
          this.listPermissSelected.push(_row)
        }
      })
      this.modalAddPermiss = false
    },
    removeCurrentPermiss (index) {
      this.listPermissSelected.splice(index, 1)
    },
    saveDataPermiss () {
      if (this.role === null || this.listPermissSelected === []) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Data Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      const permissId = []
      this.listPermissSelected.forEach((row) => {
        const obj = JSON.parse(JSON.stringify(row.id))
        permissId.push(obj)
      })
      const data = {
        role: this.role,
        permission_id: permissId
      }
      this.isLoading = true
      axios.post(this.url + '/api/v1/vendor/role', data)
        .then(() => {
          this.loadData(1)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Save role & permission berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.permissIsShow = false
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    bulkDelete () {
      this.$buefy.dialog.confirm({
        message: 'Hapus role terpilih ?',
        onConfirm: () => {
          const roleId = []
          this.paginate.data.forEach((row) => {
            if (row.isSelect) {
              roleId.push(row.id)
            }
          })
          axios.post(this.url + '/api/v1/vendor/role/bulk-delete', { id: roleId })
            .then((cb) => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus Role berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error role',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    // untuk data role management
    setEdit () {}
  },
  data () {
    return {
      role: null,
      modalAddPermiss: false,
      permissIsShow: false,
      urlPermiss: null,
      currentPermiss: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      },
      listPermissSelected: [],
      permiss: []
    }
  },
  created () {
    this.loadData()
    this.loadPermission()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
