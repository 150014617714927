import axios from 'axios'

const state = () => ({
  attendances: null,
  idHomecare: null
})

const mutations = {
  setAttendance (state, data) {
    state.attendances = data
  },
  setHomecareId (state, id) {
    state.idHomecare = id
  }
}

const getters = {
  getAttendances: (state, getters, rootState) => {
    return state.attendances
  },
  getHomecareId: (state, getters, rootState) => {
    return state.idHomecare
  }
}

const actions = {
  async attendancesHomecare ({ commit }, data) {
    try {
      const h = window.location.host

      let url
      // if (h === 'shellb2b.devkanggo.com') {
      //   url = 'https://devapi.devkanggo.com'
      // }
      // if (h.substring(0, 9) === 'localhost') {
      //   url = 'https://devapi.devkanggo.com'
      // }
      if (h === 'shellb2b.prodkanggo.com') {
        url = 'https://api.prodkanggo.com'
      }

      await axios.get(`${url}/api/v3/vendor/attendances?project_id=${data.projectId}&vendor_name=${data.companyName}&vendor_id=${data.vendorId}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            commit('setAttendance', cb.data.data)
            commit('setHomecareId', cb.data.data.project_id_homecare)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
