<template>
  <div>
    <div>
      <div class="container is-max-desktop">
        <div class="level" style="margin-top: 20px;">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                Ubah Password
              </h1>
            </div>
          </div>
        </div>
        <div style="padding: 15px;">
          <b-field style="max-width: 50%;" label="Password Lama">
            <b-input v-model="passwordLama" type="password" placeholder="Password Lama" password-reveal/>
          </b-field>
          <b-field style="max-width: 50%;" label="Password Baru">
            <b-input v-model="passwordBaru" type="password" placeholder="Password Baru" password-reveal/>
          </b-field>
          <b-field style="max-width: 50%;" label="Konfirmasi Password Baru">
            <b-input v-model="passwordBaruKonfirm" type="password" placeholder="Konfirmasi Password Baru" password-reveal/>
          </b-field>
          <b-button @click="updatePassword()" style="margin-top: 20px;" type="is-success">Simpan</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'UserUbahPassword',
  components: {},
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  methods: {
    updatePassword () {
      if (this.passwordLama === null || this.passwordBaru === null || this.passwordBaruKonfirm === null) {
        return
      }
      const data = {
        user_id: this.user.id,
        old_password: this.passwordLama,
        password: this.passwordBaru,
        confirm_pass: this.passwordBaruKonfirm
      }
      axios.put(this.url + '/api/v1/vendor/user/update-password', data)
        .then((cb) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Update password berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.passwordLama = null
          this.passwordBaru = null
          this.passwordBaruKonfirm = null
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error update password ' + err.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  },
  data () {
    return {
      passwordLama: null,
      passwordBaru: null,
      passwordBaruKonfirm: null
    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px 0px 0px 0px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
