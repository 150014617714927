import axios from 'axios'

const state = () => ({
  invoiceGabungan: null
})

const getters = {
  getInvSummary: (state, getters, rootState) => {
    return state.invoiceGabungan
  }
}

const actions = {
  loadInvSum ({ commit, state }, data) {
    setTimeout(function () {
      axios.get(data.url + '/api/v1/vendor/invoices/' + data.ids)
        .then((v) => {
          if (v.data.code === 1) {
            commit('setInvSum', v.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }, 500)
  }
}

const mutations = {
  setInvSum (state, data) {
    state.invoiceGabungan = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
