<template>
  <div>
    <div>
      <section style="margin-left: 12px; margin-bottom: 10px; margin-top: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="account-box-multiple-outline"/> &nbsp; User <br/>
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button>Tambah User</b-button>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
      <div class="level-left">
        <div @click="tabOnClick(1)" :class="{'active': tabNav === 1}" class="tab-label">
          Nama User
        </div>
        <div @click="tabOnClick(2)" :class="{'active': tabNav === 2}" class="tab-label">
          Jabatan dan Izin
        </div>
      </div>
      <div class="level-right" style="padding-right: 20px;">
        <div>
          <b-field>
            <b-select placeholder="Cari Cabang">
              <option
                  v-for="option in cabang"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div style="width: 300px; margin-left: 10px;">
          <b-field>
            <b-input placeholder="Cari Customer..."
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="searchOnClick">
            </b-input>
          </b-field>
        </div>
      </div>
    </div>

    <div v-if="tabNav === 1">
      <b-table paginated per-page="10" :data="anggotaTim" scrollable>
        <b-table-column field="name" label="Nama" v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column field="email" label="email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>
        <b-table-column field="role" label="Jabatan" v-slot="props">
          {{ props.row.role }}
        </b-table-column>
        <b-table-column field="branch" label="Cabang" v-slot="props">
          {{ props.row.branch }}
        </b-table-column>
      </b-table>
    </div>

    <div v-if="tabNav === 2" class="container" style="max-width: 600px;">
      <div class="card" style="margin-top: 20px;">
        <div class="title is-5" style="border-bottom: solid thin #f0f0f0; height: 50px; text-align: center; padding-top: 10px;">
          Jabatan dan Izin
        </div>
        <b-table :data="roles" scrollable>
          <b-table-column field="role" label="Nama Jabatan" v-slot="props">
            {{ props.row.role }}
          </b-table-column>
          <b-table-column field="total" label="Jumlah Anggota" v-slot="props">
            {{ props.row.total }}
          </b-table-column>
        </b-table>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import member from '../../api/member'
import { mapGetters } from 'vuex'

export default {
  name: 'AnggotaList',
  computed: {
    ...mapGetters('member', {
      anggotaTim: 'getAnggotaTim',
      roles: 'getRoles'
    })
  },
  components: {
  },
  methods: {
    searchOnClick () {

    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    tabOnClick (idx) {
      this.tabNav = idx
    }
  },
  data () {
    return {
      tabNav: 1,
      cabang: [
        { id: 1, name: 'ACE Aeon Mall' },
        { id: 2, name: 'ACE Mall Serpong' },
        { id: 3, name: 'ACE BTC Tangerang Selatan' }
      ]
    }
  },
  mounted () {
    member.getMembers((val) => {
      this.$store.commit('member/setAnggotaTim', val.members)
    })
    member.getRoles((val) => {
      this.$store.commit('member/setRoles', val)
    })
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px 0px 0px 0px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
