<template>
  <div>
    <div>
      <section style="margin-left: 12px; margin-bottom: 10px; margin-top: 20px;">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4 has-text-weight-medium">
                <b-icon icon="account-hard-hat" /> &nbsp; Daftar Produk
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-input placeholder="Cari Produk"
                v-model="searchProduct"
                type="search"
                icon="magnify"
                icon-clickable
                @icon-click="searchOnClick">
              </b-input>
              <button @click="btnTambahOnClick()" class="button is-success">
                <b-icon icon="plus" size="is-small" style="margin-right: 3px;" />
                Tambah Produk
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <table class="table" style="width: 100%;">
        <thead>
          <tr>
            <td>Nama</td>
            <td>Foto</td>
            <td>Tukang Wajib</td>
            <td>Tukang Opsional</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, idx) in products.items" :key="idx">
            <td>{{ v.name }}</td>
            <td>
              <img style="width: 100px;" :src="v.image_uri" />
            </td>
            <td>
              <ul>
                <li style="display: flex;" v-for="(n) in v.worker_primary" :key="n.name">
                  <div>
                    <img style="width: 30px;" :src="n.image_uri" />
                  </div>
                  <div style="margin-left: 5px;">
                    {{ n.name }}
                  </div>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li style="display: flex;" v-for="(n) in v.worker_optional" :key="n.name">
                  <div>
                    <img style="width: 30px;" :src="n.image_uri" />
                  </div>
                  <div style="margin-left: 5px;">
                    {{ n.name }}
                  </div>
                </li>
              </ul>
            </td>
            <td>
              <b-button type="is-primary">Edit</b-button>
              <b-button type="is-danger" style="margin-left: 5px;">Hapus</b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ApiProduct from '../../api/product'

export default {
  name: 'Product',
  components: {},
  methods: {
    searchOnClick () {

    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    }
  },
  data () {
    return {
      searchProduct: '',
      products: ''
    }
  },
  mounted () {
    // console.log('xxx')
    ApiProduct.getAllProducts((val) => {
      this.products = val.data
    })
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px 0px 0px 0px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
