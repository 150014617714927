/* eslint-disable */

import axios from 'axios'
import _ from 'lodash'

const _products = {
  "code": 1,
  "message": "List Produk Mitra 10",
  "data": [
    {
      "id": 1,
      "name": "Kloset Toilet",
      "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/mitra10product%2Fcloset.jpg?alt=media&token=083029f7-d43d-4db3-a4c1-4f61b4e76ff0",
      "service_list": [
        {
          "id": 5447,
          "name": "bongkar",
          "skill_list": [
            {
              "id": 1,
              "name": "Tukang Sanitair",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            },
            {
              "id": 2,
              "name": "Kenek",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            }
          ],
          "price": [
            {
              "sesi": 0,
              "price": 2750000
            },
            {
              "sesi": 1,
              "price": 1750000
            },
            {
              "sesi": 2,
              "price": 1550000
            }
          ]
        },
        {
          "id": 5448,
          "name": "pasang",
          "skill_list": [
            {
              "id": 1,
              "name": "Tukang Sanitair",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            },
            {
              "id": 2,
              "name": "Kenek",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FKeneks.png?alt=media&token=85de7442-beb1-4504-9719-ef23df512887"
            }
          ],
          "price": [
            {
              "id": 1,
              "sesi": 0,
              "price": 2750000
            },
            {
              "id": 2,
              "sesi": 1,
              "price": 1750000
            },
            {
              "id": 3,
              "sesi": 2,
              "price": 1550000
            }
          ]
        }
      ]
    },
    {
      "id": 2,
      "name": "kloset toilet shocking pink",
      "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/mitra10product%2Fcloset.jpg?alt=media&token=083029f7-d43d-4db3-a4c1-4f61b4e76ff0",
      "service_list": [
        {
          "id": 5447,
          "name": "bongkar",
          "skill_list": [
            {
              "id": 1,
              "name": "Tukang Sanitair",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            },
            {
              "id": 2,
              "name": "Kenek",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FKeneks.png?alt=media&token=85de7442-beb1-4504-9719-ef23df512887"
            }
          ],
          "price": [
            {
              "sesi": 0,
              "price": 2750000
            },
            {
              "sesi": 1,
              "price": 1750000
            },
            {
              "sesi": 2,
              "price": 1550000
            }
          ]
        },
        {
          "id": 5448,
          "name": "pasang",
          "skill_list": [
            {
              "id": 1,
              "name": "Tukang Sanitair",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            },
            {
              "id": 2,
              "name": "Kenek",
              "image_uri": "https://firebasestorage.googleapis.com/v0/b/kang-go.appspot.com/o/iconandroid%2Ficon_tukang%2FT.%20Cleaning%20Service.png?alt=media&token=39ff336e-438a-4b53-90e0-4f5563f14a20"
            }
          ],
          "price": [
            {
              "id": 1,
              "sesi": 0,
              "price": 2750000
            },
            {
              "id": 2,
              "sesi": 1,
              "price": 1750000
            },
            {
              "id": 3,
              "sesi": 2,
              "price": 1550000
            }
          ]
        }
      ]
    }
  ]
};


/** http://api-gateway.devkanggo.com/products/list?page=1&name=cat */
// const baseUrl = "http://vendor-products.devkanggo.com/products/list"

const baseUrl = "http://mitra10newapi.devkanggo.com/api/v1/vendor/product_list"

export default {
  getByTransaction (id, cb) {
    setTimeout(() => cb(_products2), 1000)
  },
  getAllProducts (cb) {
    return _products.data
    // axios.get(baseUrl).then((data) => {
    //   console.log(data)
    //  cb(data.data)
    // })
  }
}