<template>
  <div class="mt-5">
    <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
      <div class="level-left">
        <div @click="selectTab(0)" :class="{'active': activeTab === 0}" class="tab-label">
          Approval
        </div>
        <!-- <div @click="selectTab(1)" :class="{'active': activeTab === 1}" class="tab-label">
          Riwayat Approval
        </div> -->
      </div>
    </div>

    <div v-if="activeTab === 0">
      <b-table
        class="mt-3"
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="penanggung_jawab" label="Penanggung Jawab" v-slot="props">
          {{ props.row.user_vendor_name }}
        </b-table-column>
        <b-table-column field="nominal" label="Nominal Top Up" v-slot="props">
          Rp.{{ props.row.nominal | numFormat }}
        </b-table-column>
        <b-table-column field="status" label="Status" v-slot="props">
          {{ props.row.status }}
        </b-table-column>
        <b-table-column field="image_uri" label="Bukti Transfer" v-slot="props">
          <div style="display: flex;">
            <div>
              <vue-easy-lightbox
              escDisabled
              moveDisabled
              :visible="visible"
              :imgs="props.row.image"
              :index="index"
              @hide="handleHide">
              </vue-easy-lightbox>
              <img :src="props.row.image" @click="showSingle" style="width: 100px;" />
            </div>
          </div>
        </b-table-column>
        <b-table-column field="catatan" label="Act" v-slot="props">
          <b-button v-if="props.row.status === 'pending'" type="is-success" @click="confirmApproved(props.row)" style="margin-right: 5px;">Approve</b-button>
          <b-button type="is-danger" @click="rejectedTopup(props.row)">Rejected</b-button>
        </b-table-column>
      </b-table>
    </div>

    <!-- <div v-if="activeTab === 1">
      <b-table
        class="mt-3"
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="penanggung_jawab" label="Penanggung Jawab" v-slot="props">
          {{ props.row.user_vendor_name }}
        </b-table-column>
        <b-table-column field="nominal" label="Nominal Top Up" v-slot="props">
          {{ props.row.nominal | numFormat }}
        </b-table-column>
        <b-table-column field="image_uri" label="Bukti Transfer" v-slot="props">
          <div style="display: flex;">
            <div>
              <img :src="props.row.image" style="width: 100px;" />
            </div>
          </div>
        </b-table-column>
        <b-table-column field="status" label="Status" v-slot="props">
          {{ props.row.status }}
        </b-table-column>
      </b-table>
    </div> -->

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ApprovalTopUp',
  data () {
    return {
      imgs: '',
      visible: false,
      index: 0,
      activeTab: 0,
      filterTanggal: null,
      dateSelected: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    }
  },
  methods: {
    showSingle () {
      this.show()
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    confirmApproved (val) {
      this.$buefy.dialog.confirm({
        message: 'Continue on approved?',
        onConfirm: () => this.approvedTopup(val)
      })
    },
    selectTab (val) {
      this.activeTab = val
    },
    clearInput () {
      this.nominal = null
      this.imageBukti = null
    },
    bayarPendingTopup () {
      this.clearInput()
      this.bayarPendingShow = true
    },
    convDate (val) {
      return moment(val).format('DD MMMM YYYY')
    },
    onPageChange (page) {
      this.getData(page)
    },
    getData (page) {
      this.paginate.loading = true
      let tgl1 = null
      let tgl2 = null
      if (this.filterTanggal === null) {
        tgl1 = moment().startOf('month').format('YYYY-MM-DD')
        tgl2 = moment().endOf('month').format('YYYY-MM-DD')
      }
      const pr = '/api/v1/vendor/upload/transfer?page=' + page + '&limit=10&start_date=' + tgl1 + '&end_date=' + tgl2
      axios.get(this.url + pr)
        .then((cb) => {
          this.paginate.data = cb.data.data
          this.paginate.total = (cb.data.data.total_page / this.paginate.perPage) + 1
          this.paginate.page = cb.data.data.current_page
          this.paginate.loading = false
        })
    },
    approvedTopup (val) {
      let data = null
      let url = null

      data = {
        status: 'approved',
        user_action_id: this.user.id
      }

      url = this.url + '/api/v1/vendor/upload/transfer/' + val.id + '/action'
      this.isLoading = true
      axios.put(url, data)
        .then(() => {
          this.getData(1)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'success!',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    }
  },
  props: {
    date: Object
  },
  created () {
    this.selectTab(0)
    this.getData(1)
  }
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px 0px 0px 0px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
