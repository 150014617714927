<template>
  <div>
    <div class="columns mt-2">
      <div class="column">
        <b-button @click="tambahOnClick()" type="is-success" icon-left="plus">Permission</b-button>
        <b-button
          @click="bulkDelete()"
          icon-left="delete"
          type="is-danger"
          style="margin-left: 5px;"
          :disabled="!hasSelected"/>
      </div>
    </div>

    <div>
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="id" label="#" v-slot="props">
          <b-checkbox v-model="props.row.isSelect" />
        </b-table-column>
        <b-table-column field="permission_name" label="Permission Name" v-slot="props">
          {{ props.row.permission_name }}
        </b-table-column>
        <b-table-column field="controller_path" label="Path" v-slot="props">
          {{ props.row.controller_path }}
        </b-table-column>
      </b-table>
    </div>

    <b-modal
      v-model="formPermiss"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah Permission</p>
            <button type="button" class="delete" @click="formPermiss = false"/>
          </header>
          <section class="modal-card-body">
            <div class="card">
              <div class="card-content">
                <b-field label="Permission Name">
                  <b-input v-model="name" placeholder="Create Project" required></b-input>
                </b-field>
                <b-field label="URL">
                  <b-input v-model="urlPermiss" placeholder='/create-project' required></b-input>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button @click="savePermission()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

  </div>
</template>

<script>
// import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanPermission',
  data () {
    return {
      formPermiss: false,
      name: null,
      urlPermiss: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    hasSelected () {
      return this.paginate.data.find(o => o.isSelect === true)
    }
  },
  components: {},
  methods: {
    tambahOnClick () {
      this.formPermiss = true
      this.clearInput()
    },
    clearInput () {
      this.name = null
      this.urlPermiss = null
    },
    onPageChange (page) {
      this.loadData(page)
    },
    async loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = this.url + '/api/v1/vendor/permission?page=' + this.paginate.page + '&limit=' + this.paginate.perPage
      await axios.get(url)
        .then((response) => {
          if (response.data.code === 1) {
            this.paginate.data = response.data.data
            this.paginate.total = (response.data.total_page * this.paginate.perPage)
            this.paginate.page = response.data.current_page
            this.paginate.loading = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    bulkDelete () {
      this.$buefy.dialog.confirm({
        message: 'Hapus permission terpilih ?',
        onConfirm: () => {
          const missionId = []
          this.paginate.data.forEach((row) => {
            if (row.isSelect) {
              missionId.push(JSON.parse(row.id))
            }
          })
          axios.post(this.url + '/api/v1/vendor/permission/bulk-delete', { id: missionId })
            .then((cb) => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 4000,
                message: 'Hapus permission berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 4000,
                message: 'Error hapus permission!',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    savePermission () {
      let data = null
      let url = null
      if (this.name === null || this.urlPermiss === null) {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Data Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      data = {
        name: this.name,
        url: this.urlPermiss
      }
      url = this.url + '/api/v1/vendor/permission'
      this.isLoading = true
      axios.post(url, data)
        .then(() => {
          this.loadData(1)
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Save Permission berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.formPermiss = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    }
  },
  created () {
    this.loadData(1)
  }
}
</script>
