
const state = () => ({
  anggotaTim: [],
  roles: []
})

const getters = {
  getAnggotaTim: (state, getters, rootState) => {
    return state.anggotaTim
  },
  getRoles: (state, getters, rootState) => {
    return state.roles
  }
}

const actions = {

}

const mutations = {
  setAnggotaTim (state, data) {
    state.anggotaTim = data
  },
  setRoles (state, data) {
    state.roles = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
