<template>
  <div>
    <div class="level mt-2">
      <div class="level-left">
        <div class="level-item">
          <b-field>
            <b-input
              placeholder="Cari Client"
              v-model="search"
              type="search"
              icon="magnify"
              icon-clickable
              @keyup.native.enter="cariClient"
              @icon-click="cariClient">
            </b-input>
          </b-field>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button @click="addClient()" type="is-success" icon-left="plus">Tambah Client</b-button>
        </div>
      </div>
    </div>

    <!-- table list client -->
    <b-table
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="code" label="Code" v-slot="props">
        {{ props.row.code }}
      </b-table-column>
      <b-table-column field="name" label="Nama Client" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="phone" label="Phone Client" v-slot="props">
        {{ props.row.phone }}
      </b-table-column>
      <!-- <b-table-column field="email" label="Email Client" v-slot="props">
        {{ props.row.email }}
      </b-table-column> -->
      <b-table-column field="act" label="Action" v-slot="props">
        <b-button icon-left="pencil" @click="setEdit(props.row)" type="is-success" /> &nbsp;
        <b-button icon-left="delete" @click="deleteClient(props.row)" type="is-danger" />
      </b-table-column>
    </b-table>

    <!-- modal add client -->
    <b-modal
      v-model="modalTambahClient"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah Client</p>
            <button type="button" class="delete" @click="modalTambahClient = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Client"
              :type="{ 'is-danger': hasError, 'is-success': hasSuccess }"
              :message="[ { 'Nama Tidak Boleh Kosong!': hasError } ]">
              <b-input v-model="name" placeholder="Client Name" required></b-input>
            </b-field>
            <b-field label="Email Client"
              :type="{ 'is-danger': hasError, 'is-success': hasSuccess }"
              :message="[ { 'Email Tidak Boleh Kosong!': hasError } ]">
              <b-input type="email" v-model="email" placeholder="Client Email" required></b-input>
            </b-field>
            <b-field label="Phone Client"
              :type="{ 'is-danger': hasError, 'is-success': hasSuccess }"
              :message="[
                { 'Nomor Telpon Tidak Boleh Kosong!': hasError },
                { 'Nomor Telpon harus memiliki minimal 7 karakter!': hasError }
              ]">
              <b-input type="number" min="7" max="20" v-model="phone" placeholder="Client Phone" required></b-input>
            </b-field>
            <b-field label="Address Client"
              :type="{ 'is-danger': hasError, 'is-success': hasSuccess }"
              :message="[ { 'Alamat Tidak Boleh Kosong!': hasError } ]">
              <b-input maxlength="200" v-model="address" placeholder="Client address" type="textarea" required></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="saveUser()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

    <!-- modal edit user with role -->
    <b-modal
      v-model="modalEditClient"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Edit CLient</p>
            <button type="button" class="delete" @click="modalEditClient = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Client"
              :type="{ 'is-danger': hasErrorName, 'is-success': hasSuccess }"
              :message="[ { 'Nama Tidak Boleh Kosong!': hasErrorName } ]">
              <b-input v-model="name" placeholder="Client Name" required></b-input>
            </b-field>
            <b-field label="Email Client"
              :type="{ 'is-danger': hasErrorEmail, 'is-success': hasSuccess }"
              :message="[ { 'Email Tidak Boleh Kosong!': hasErrorEmail } ]">
              <b-input type="email" v-model="email" placeholder="Client Email" required></b-input>
            </b-field>
            <b-field label="Phone Client"
              :type="{ 'is-danger': hasErrorPhone, 'is-success': hasSuccess }"
              :message="[
                { 'Nomor Telpon Tidak Boleh Kosong!': hasErrorPhone },
                { 'Nomor Telpon harus memiliki minimal 7 karakter!': hasErrorPhone }
              ]">
              <b-input type="number" min="7" max="20" v-model="phone" placeholder="Client Phone" required></b-input>
            </b-field>
            <b-field label="Address Client"
              :type="{ 'is-danger': hasErrorAddress, 'is-success': hasSuccess }"
              :message="[ { 'Alamat Tidak Boleh Kosong!': hasErrorAddress } ]">
              <b-input maxlength="200" v-model="address" placeholder="Client address" type="textarea" required></b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="updateClient()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Client',
  data () {
    return {
      hasSuccess: false,
      hasError: false,
      hasErrorName: false,
      hasErrorPhone: false,
      hasErrorEmail: false,
      hasErrorAddress: false,
      search: null,
      modalTambahClient: false,
      modalEditClient: false,
      isLoading: false,
      isEdit: false,
      name: null,
      email: null,
      phone: null,
      address: null,
      code: null,
      client_id: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      },
      roles: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {},
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.loadData(1)
      }
    }
  },
  methods: {
    searchOnClick () {},
    setFormat (v) { return moment(v).format('DD MMMM YYYY') },
    onPageChange (v) { this.loadData(v) },
    async loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = this.url + '/api/v1/vendor/client?page=' + this.paginate.page + '&limit=' + this.paginate.perPage
      await axios.get(url)
        .then((vl) => {
          if (vl) {
            this.paginate.data = vl.data.data.data
            this.paginate.total = (vl.data.data.pageTotal * this.paginate.perPage)
            this.paginate.page = vl.data.data.thisPage
            this.paginate.loading = false
          }
        })
        .catch((err) => {
          this.$buefy.snackbar.open({
            message: err,
            queue: false
          })
        })
    },
    cariClient () {
      if (this.search === null) { return }
      this.loading = true
      const url = this.url + '/api/v1/vendor/client?page=1&limit=' + this.paginate.perPage + '&keyword=' + this.search
      axios.get(url)
        .then((hs) => {
          this.paginate.data = hs.data.data.data
          this.paginate.total = (hs.data.data.pageTotal * this.paginate.perPage)
          this.paginate.page = hs.data.data.pageTotal
          this.paginate.loading = false
        })
        .catch(() => {
          this.paginate.loading = false
        })
    },
    clearInput () {
      this.name = null
      this.phone = null
      this.email = null
      this.address = null
    },
    deleteClient (val) {
      this.clientSelected = val
      this.$buefy.dialog.confirm({
        message: 'Apakah anda ingin menghapus Client : ' + this.clientSelected.name + ' ?',
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/client/' + this.clientSelected.id)
            .then(() => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 3000,
                message: 'Hapus Client berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 4000,
                message: 'Error hapus CLient!',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    saveUser () {
      let data = null
      let url = null
      if (!this.isEdit) {
        if (this.name === null || this.name === '' || this.email === null || this.email === '' ||
            this.phone === null || this.phone === '' || this.address === null || this.address === '') {
          this.hasError = true
          return
        }

        const code = this.name.split('')
        const codeName = code[0] + code[2] + code[4]

        data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          code: codeName.toUpperCase()
        }

        if (this.name.length > 0 || this.address.length > 0 || this.email.length > 0 || this.phone.length > 0) {
          this.hasSuccess = true

          url = this.url + '/api/v1/vendor/client'
          this.isLoading = true
          axios.post(url, data)
            .then(() => {
              this.loadData(1)
              this.$buefy.toast.open({
                duration: 3000,
                message: 'Save Client Berhasil!...',
                position: 'is-bottom',
                type: 'is-info'
              })
              this.clearInput()
              this.modalTambahClient = false
              this.isLoading = false
            })
            .catch((e) => {
              this.$buefy.toast.open({
                duration: 5000,
                message: e,
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.isLoading = false
              this.clearInput()
            })
        } else {
          this.$buefy.snackbar.open({
            message: 'Data masih kurang lengkap!',
            queue: false
          })
          this.isLoading = false
        }
      }
    },
    updateClient () {
      let data = null
      let url = null
      if (this.isEdit) {
        if (this.name === null || this.name === '') {
          this.hasErrorName = true
          return
        }

        if (this.email === null || this.email === '') {
          this.hasErrorEmail = true
          return
        }

        if (this.phone === null || this.phone === '') {
          this.hasErrorPhone = true
          return
        }

        if (this.address === null || this.address === '') {
          this.hasErrorAddress = true
          return
        }

        data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          code: this.code
        }

        if (this.name.length > 0 || this.address.length > 0 || this.email.length > 0 || this.phone.length > 0) {
          this.hasSuccess = true

          this.isLoading = true
          const clienId = this.client_id
          url = this.url + '/api/v1/vendor/client/' + clienId
          axios.put(url, data)
            .then(() => {
              this.$buefy.toast.open({
                duration: 3000,
                message: 'Update user berhasil!...',
                position: 'is-bottom',
                type: 'is-info'
              })
              this.loadData(1)
              this.clearInput()
              this.modalEditClient = false
              this.isLoading = false
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 4000,
                message: 'Error save',
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.isLoading = false
            })
        }
      }
    },
    roleUser () {
      axios.get(this.url + '/api/v1/vendor/role')
        .then((cb) => {
          this.roles = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setEdit (val) {
      this.isEdit = true
      this.client_id = val.id
      this.name = val.name
      this.email = val.email
      this.phone = val.phone
      this.address = val.address
      this.code = val.code
      this.modalEditClient = true
    },
    addClient () {
      this.modalTambahClient = true
      this.clearInput()
    }
  },
  created () {
    this.loadData(1)
    this.roleUser()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
