<template>
  <div>
    <button @click="showTopUp()" class="button is-success">
      <b-icon icon="plus" size="is-small" style="margin-right: 3px;" />
      Top Up Saldo
    </button>
    <b-modal v-model="choiceIsView" :width="700">
      <div class="card">
        <div style="border-bottom: solid thin #f0f0f0; padding-bottom: 10px;">
        <h1 style="text-align: center; margin-top: 15px;" class="title is-5">Top Up Saldo</h1>
        </div>
        <div style="display: flex;">
          <div style="width: 50%; text-align: center; padding: 20px;">
            <img style="width: 100px; margin-top: 25px;" src="../../assets/trf_va.png" />
            <div class="title is-5" style="margin-top: 20px;">
              Transfer Manual
            </div>
          </div>
          <div style="width: 50%; text-align: center; padding: 20px">
            <div class="field">
              <p class="control has-icons-left has-icons-right">
                <input class="input" type="text" placeholder="Masukan nominal Top-Up">
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i> Rp.
                </span>
              </p>
            </div>
            <div style="margin-top: 20px;">
              <b-button style="width: 100%;">Unggah Bukti Transfer</b-button>
            </div>
          </div>
        </div>
        <div class="card-footer" style="padding: 20px;">
          <b-button style="width: 100%;">Update</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="confirmIsView" :width="400">
      <div class="card">
        <div style="border-bottom: solid thin #f0f0f0; padding-bottom: 10px;">
          <h1 style="text-align: center; margin-top: 15px;"
            class="title is-5">Top Up Saldo</h1>
        </div>
        <div style="text-align: center; padding: 20px;">
          <img style="width: 60px;" src="../../assets/invoice_terbuat.png" />
          <p style="margin: 20px 0px 10px 0px;">Invoice telah dibuat dengan nominal sebesar</p>
          <h1 class="title is-4">Rp. 10.000.000</h1>
          Mohon buat pembayaran sebelum <br/> <strong> 20 december 2020 </strong>
          <p style="margin-bottom: 10px; margin-top: 20px;">
            <b-button @click="viewnav(4)">Lanjutkan Pembayaran</b-button>
          </p>
          Invoice yang belum terbayar dapat dilihat dihalaman: <strong style="color: grey;"> Finance > Belum Bayar </strong>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TopUp',
  watch: {
  },
  methods: {
    showTopUp () {
      this.viewnav(1)
    },
    viewnav (newVal) {
      this.clearWindow()
      if (newVal === 1) {
        this.choiceIsView = true
      }
      if (newVal === 2) {
        this.saldoIsView = true
      }
      if (newVal === 3) {
        this.confirmIsView = true
      }
      if (newVal === 4) {
        this.bankIsView = true
      }
      if (newVal === 5) {
        this.berhasilIsView = true
      }
    },
    clearWindow () {
      this.choiceIsView = false
      this.saldoIsView = false
      this.confirmIsView = false
      this.bankIsView = false
      this.berhasilIsView = false
    }
  },
  data () {
    return {
      choiceIsView: false,
      saldoIsView: false,
      confirmIsView: false,
      bankIsView: false,
      berhasilIsView: false,
      nominalConfirm: false
    }
  }
}
</script>

<style>
</style>
