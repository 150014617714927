<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <div class="column is-12">
      <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
        <div class="level-left">
          <!-- TABS MENU -->
          <div class="columns mt-5">
            <div class="column">
              <div class="tabs is-centered is-boxed">
                <ul>
                  <li :class="{'is-active' : activeTab === 1}">
                    <a @click="selectTab(1)">
                      <span>List Transaksi</span>
                    </a>
                  </li>
                  <li :class="{'is-active' : activeTab === 3}">
                    <a @click="selectTab(3)">
                      <span>Invoice Perproyek</span>
                    </a>
                  </li>
                  <li :class="{'is-active' : activeTab === 5}">
                    <a @click="selectTab(5)">
                      <span>Invoice Gabungan</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /TABS MENU -->
        </div>

        <div class="level-right">
          <div class="columns is-full mb-3">
            <div class="column">
              <div class="columns is-mobile">
                <div class="column is-two-fifths">
                  <b-datepicker
                    v-model="filterTanggalStart"
                    ref="date"
                    placeholder="Start Date"
                    icon="calendar-today"
                    clearable
                    trap-focus>
                  </b-datepicker>
                </div>
                <div class="my-4"> - </div>
                <div class="column is-two-fifths">
                  <b-datepicker
                    v-model="filterTanggalEnd"
                    ref="date"
                    placeholder="End Date"
                    icon="calendar-today"
                    clearable
                    trap-focus>
                  </b-datepicker>
                </div>
                <div class="column">
                  <b-button @click="filterTanggal()" type="is-primary"> Filter </b-button> &nbsp;
                  <!-- <b-button @click="clearDate()" type="is-danger"> Clear </b-button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="activeTab === 1">
      <list-transaksi-vue :date="valueDate"/>
    </div>
    <div v-show="activeTab === 3">
      <invoice-proyek :date="valueDate" />
    </div>
    <div v-show="activeTab === 5">
      <invoice-proyek-sum/>
    </div>
    <!-- <div v-show="activeTab === 0">
      <jurnal :date="valueDate" />
    </div> -->
    <!-- <div v-show="activeTab === 1">
      <riwayat-top-up :date="valueDate" />
    </div> -->
    <!-- <div v-show="activeTab === 4">
      <invoice-overtime :date="valueDate"></invoice-overtime>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import guard from '../../routerGuard'
import TitleBar from '@/components/TitleBar'
import InvoiceProyek from './InvoiceProyek.vue'
import ListTransaksiVue from './ListTransaksi.vue'
import InvoiceProyekSum from './InvoiceProyekSum.vue'
// import Jurnal from './Jurnal.vue'
// import RiwayatTopUp from './RiwayatTopUp.vue'
// import InvoiceOvertime from './InvoiceOvertime.vue'
// import PendingTopUp from './PendingTopUp.vue'

export default {
  name: 'Laporan',
  data () {
    return {
      activeTab: 1,
      dateSelected: [],
      startDate: '',
      endDate: '',
      filterTanggalStart: null,
      filterTanggalEnd: null,
      dateSelectedStart: null,
      dateSelectedEnd: null,
      fromDate: null,
      toDate: null,
      valueDate: null
      // filterTanggal: null,
      // cabang: [
      //   { id: 1, name: 'Mitra 10 Aeon Mall' },
      //   { id: 2, name: 'Mitra 10 Mall Serpong' },
      //   { id: 3, name: 'Mitra 10 BTC Tangerang Selatan' }
      // ]
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      invSum: 'invoice/getInvSummary'
    }),
    titleStack () {
      return [
        'Admin',
        'Laporan'
      ]
    }
  },
  components: {
    ListTransaksiVue,
    TitleBar,
    InvoiceProyek,
    InvoiceProyekSum
    // Jurnal,
    // RiwayatTopUp,
    // InvoiceOvertime
    // PendingTopUp,
  },
  watch: {
    filterTanggalStart (val) {
      if (val === null) {
        return new Date()
      } else {
        this.dateSelected.push({
          fromDate: moment(val).format('YYYY-MM-DD')
        })
      }
    },
    filterTanggalEnd (val) {
      if (val === null) {
        return new Date()
      } else {
        this.dateSelected.push({
          toDate: moment(val).format('YYYY-MM-DD')
        })
      }
    }
  },
  methods: {
    filterTanggal () {
      if (this.dateSelected !== null) {
        this.valueDate = {
          tgl1: this.dateSelected[0].fromDate,
          tgl2: this.dateSelected[1].toDate
        }
        this.dateSelected.splice(0)

        const clientData = {
          url: this.url
        }
        this.$store.dispatch('setup/loadClient', clientData)
      } else {
        this.resetDate()
      }
    },
    initialState () {
      return {
        valueDate: {
          tgl1: null,
          tgl2: null
        }
      }
    },
    resetDate () {
      Object.assign(this.$data, this.initialState())
    },
    clearDate () {
      this.filterTanggalStart = null
      this.filterTanggalEnd = null
    },
    selectTab (val) {
      this.activeTab = val
    },
    btnTambahOnClick () {
      this.$router.push('/project/input')
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    searchOnClick () {},
    onPageChange (v) {},
    onDateRangeChange (val) {}
  },
  created () {
    guard.check(this.$router, this.$router.currentRoute.path)
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.dash-container {
  padding: 20px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    background: linear-gradient(to right, #FF0000,#FF1A1A, #FF3333, #ffffff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
  }
}
.is-hidden {
  visibility: hidden;
}
.is-show {
  visibility: visible;
}
</style>
