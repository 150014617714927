import store from './store/index'

export default {
  check (router, _path) {
    const roles = store.getters['auth/roles']
    const rolePath = store.getters['auth/rolePath']
    if (roles === null) {
      router.push('/')
    } else {
      let check = false
      let path = _path
      if (_path.substring(0, 15) === '/project/detail') {
        path = _path.substring(0, 15)
      }
      rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = true
        }
      })
      if (check === false) {
        router.push('/')
      }
    }
  }
}
