
const state = () => ({
  jurnal: []
})

const getters = {
  getJurnal: (state, getters, rootState) => {
    return state.jurnal
  }
}

const actions = {

}

const mutations = {
  setJurnal (state, data) {
    state.jurnal = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
