/* eslint-disable */

const _list = {
  "code": 1,
  "message": "List Jurnal",
  "data": [
    {
      "project_name": "",
      "customer_name": "",
      "type": "Top up",
      "beginning_balance": "3000000",
      "mutation": "500000",
      "ending_balance": "3500000",
      "pic": "ireni imas",
      "branch": "Teraskota - Tangerang",
      "noted": "top up",
      "timestamp": "2020-12-14 10:37:00"
    },
    {
      "project_name": "ACE_AEON_690",
      "customer_name": "Ireni",
      "type": "Project",
      "beginning_balance": "3500000",
      "mutation": "500000",
      "ending_balance": "3000000",
      "pic": "nurhayati",
      "branch": "Teraskota - Tangerang",
      "noted": "project",
      "timestamp": "2020-12-14 10:40:00"
    },
    {
      "project_name": "ACE_AEON_690",
      "customer_name": "Ireni",
      "type": "Refund",
      "beginning_balance": "3000000",
      "mutation": "500000",
      "ending_balance": "3500000",
      "pic": "nurhayati",
      "branch": "Teraskota - Tangerang",
      "noted": "refund",
      "timestamp": "2020-12-14 10:50:00"
    }
  ]
}

export default {
  getJurnal (cb) {
    setTimeout(() => cb(_list.data), 100)
  }
}