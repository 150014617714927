<template>
  <div>
    <div>
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="name" label="Nama" v-slot="props">
          <div @click="setEdit(props.row)" style="color: green; cursor: pointer;">{{ props.row.name }}</div>
        </b-table-column>
        <b-table-column field="percentage" label="Jumlah" v-slot="props">
          {{ props.row.percentage }}
        </b-table-column>
      </b-table>
    </div>
    <b-modal
      v-model="modalIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div v-if="selected !== null" class="modal-card" style="width: 400px;">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ selected.name }}</p>
            <button type="button" class="delete" @click="modalIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field>
              <b-input icon-right="percent" v-model="selected.percentage" required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="save()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
// import project from '../../api/project'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanBiayaBankPph',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {},
  watch: {},
  methods: {
    setFormat () {},
    onPageChange () {},
    loadData () {
      axios.get(this.url + '/api/v1/vendor/fee_vendor?type=all')
        .then((cb) => {
          this.paginate.data = cb.data.data
          this.paginate.total = 1
          this.modalIsShow = false
          this.isLoading = false
        })
    },
    setEdit (val) {
      this.selected = val
      this.modalIsShow = true
    },
    save () {
      this.isLoading = true
      axios.put(this.url + '/api/v1/vendor/update_fee_vendor', {
        id: this.selected.id,
        percentage: parseInt(this.selected.percentage)
      })
        .then(() => {
          this.loadData()
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Update Data berhasil!...',
            position: 'is-bottom',
            type: 'is-success'
          })
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error update data',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  },
  data () {
    return {
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      },
      selected: null,
      modalIsShow: false,
      isLoading: false
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
