<template>
  <div>
    <div class="card mt-2" v-for="(v, k) in filterWorker" :key="k">
      <header class="card-header" style="background-color: grey;">
        <p class="card-header-title" style="color: snow">{{ convDate(v.dateSesiList) }} </p>
      </header>

      <div class="card-content">
        <div class="content">
          <!-- detail worker -->
          <div class="columns">
            <div class="box">
              <div class="columns is-vcentered mt-1">
                <div class="column is-one-quarter mx-2" style="width: 5rem;">
                  <img :src="v.workerPhoto" style="max-width: 5rem;"/>
                </div>
                <div class="column mx-auto">
                  <span class="has-text-right has-text-black-bis is-size-6">
                    <strong>{{ v.workerName }} - {{ v.workerIdList }}</strong> <br/>
                    {{ v.skill }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="columns" style="border: 1px solid black">
            <!--- List Photo Before --->
            <div class="column" style="border: 1px solid black">
              <p class="has-text-weight-bold">List Photo Before</p>
              <div>
                <ul v-for="(item1, idx) in filterBefore" :key="idx" @click="openModalImage(item1.photoBefore)">
                  <li v-if="v.workerIdList === item1.workerId">
                    <img :src="item1.photoBefore" alt="" loading="lazy">
                  </li>
                </ul>
              </div>
            </div>
            <!--- List Photo After --->
            <div class="column" style="border: 1px solid black">
              <p class="has-text-weight-bold">List Photo After</p>
              <div>
                <ul v-for="(item1, idx) in filterAfter" :key="idx" @click="openModalImage(item1.photoAfter)">
                  <li v-if="v.workerIdList === item1.workerId">
                    <img :src="item1.photoAfter" alt="" loading="lazy">
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section" v-if="filterWorker.length === 0">
      <div class="content has-text-grey has-text-centered">
        <template>
          <p>
            <b-icon icon="emoticon-sad" size="is-large"/>
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>

    <!-- modal image preview -->
    <b-modal v-model="isImageModalActive">
      <p class="image is-4by3">
        <img :src="imagePath">
      </p>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'BeforeAfterProject',
  props: ['project'],
  data () {
    return {
      uploadReady: true,
      selectedFile: null,
      inProject: null,
      statusProject: null,
      photosList: null,
      photoListAfter: null,
      isImageModalActive: false,
      imagePath: null
    }
  },
  components: {},
  watch: {},
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles'
    }),
    filterWorker () {
      const data = []
      if (this.project !== null) {
        this.project.worker_list.forEach((v) => {
          data.push({
            workerIdList: v.worker_id,
            dateSesiList: v.date_sesi,
            workerPhoto: v.worker_image,
            workerName: v.worker_name
          })
        })
      }
      return data
    },
    filterBefore () {
      const data = []
      if (this.project !== null) {
        this.project.photo.photo_before.forEach((v) => {
          data.push({
            id: v.id,
            workerId: v.worker_id,
            dateSesi: v.date_sesi,
            photoBefore: v.image
          })
        })
      }
      return data
    },
    filterAfter () {
      const data = []
      if (this.project !== null) {
        this.project.photo.photo_after.forEach((v) => {
          data.push({
            id: v.id,
            workerId: v.worker_id,
            dateSesi: v.date_sesi,
            photoAfter: v.image
          })
        })
      }
      return data
    }
  },
  methods: {
    reset () {
      const input = this.$refs.fileUpload
      input.type = 'text'
      input.type = 'file'
    },
    clear () {
      this.uploadReady = false
      this.$nextTick(() => {
        this.uploadReady = true
      })
    },
    resetImageAfter () {
      this.selectedFile = ''
      this.file = ''
      this.selectedFile.name = ''
      this.$refs.fileUploadAfter.value = ''
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    openModalImage (pathsImage) {
      this.imagePath = pathsImage
      this.isImageModalActive = true
    }
  },
  mounted () {},
  created () {}
}
</script>

<style lang="scss" scoped>
.is-hidden {
  visibility: hidden;
}

.is-show {
  visibility: visible;
}
.previewBlock {
  display: block;
  cursor: pointer;
  width: 300px;
  height: 280px;
  margin: 0 auto 20px;
  background-position: center center;
  background-size: cover;
}

ul {
  display: flex;
  flex-wrap: wrap;
}

li {
  height: 20vh;
  flex-grow: 3;
}

li:last-child {
  flex-grow: 5;
}

img {
  max-height: 100%;
  min-width: 50%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-aspect-ratio: 1/1) {
  li {
    height: 15vh;
  }
}

@media (max-height: 480px) {
  li {
    height: 50vh;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }
  img {
    width: 100%;
    max-height: 55vh;
    min-width: 0;
  }
}
</style>
