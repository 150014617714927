<template>
  <div class="mt-5">
    <!-- <div class="level" style="border-bottom: solid thin #f0f0f0; margin-bottom: 0px;">
      <div class="level-left"></div>
      <div class="level-right" style="margin-right: 6rem;">
        <div class="level-item">
          <b-datepicker
            range
            v-model="filterTanggal"
            placeholder="Filter Tanggal"
            icon="calendar-today"
            trap-focus>
          </b-datepicker>
        </div>
      </div>
    </div> -->

    <b-table
      class="mt-3"
      :total="paginate.total"
      :per-page="paginate.perPage"
      :data="paginate.data"
      :loading="paginate.loading"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
      backend-pagination
      scrollable>
      <b-table-column field="code" label="Bank Code" v-slot="props">
        {{ props.row.bank_code }}
      </b-table-column>
      <b-table-column field="virtual_account" label="Virtual Account" v-slot="props">
        {{ props.row.virtual_account }}
      </b-table-column>
      <b-table-column field="method" label="Payment Method" v-slot="props">
        {{ props.row.method }}
      </b-table-column>
      <!-- <b-table-column field="catatan" label="Act" v-slot="props">
        <b-button type="is-success" @click="bayarPendingTopup(props.row)" style="margin-right: 5px;">Bayar</b-button>
        <b-button type="is-danger" @click="exportInvoice(props.row)">Batalkan</b-button>
      </b-table-column> -->
    </b-table>

    <!-- Modal Add Sesi -->
    <!-- <b-modal
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      v-model="bayarPendingShow">
      <div style="width: 650px;" class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Upload Bukti Pembayaran</p>
          <button
          type="button"
          class="delete"
          @click="bayarPendingShow = false" />
        </header>
        <form @submit.prevent="uploadBuktiPembayaran()" enctype="multipart/form-data">
          <section class="modal-card-body">
            <div class="container">
              <b-field label="nominal topup">
                <b-input
                  v-model="nominal"
                  name="filename"
                  placeholder="nominal topup"
                  required>
                </b-input>
              </b-field>
              <div class="form-group">
                <input
                  type="file"
                  name="imageBukti"
                  @change="onFileUpload"
                />
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button
            @click="uploadBuktiPembayaran()"
            type="is-success"
            label="Simpan"
            />
          </footer>
        </form>
      </div>
    </b-modal> -->

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'laporanPendingTopUp',
  data () {
    return {
      file: '',
      transfer: null,
      nominal: '',
      imageBukti: null,
      bayarPendingShow: false,
      filterTanggal: null,
      dateSelected: null,
      idPending: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 10,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    }
  },
  methods: {
    clearInput () {
      this.nominal = null
      this.imageBukti = null
    },
    exportInvoice () {
      // console.log(val)
    },
    bayarPendingTopup (val) {
      this.bayarPendingShow = true
      this.clearInput()
      this.idPending = val.id
    },
    convDate (val) {
      return moment(val).format('DD MMMM YYYY')
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    onFileUpload (event) {
      this.imageBukti = event.target.files[0]
    },
    // uploadBuktiPembayaran () {
    //   const formData = new FormData()
    //   formData.append('nominal', this.nominal)
    //   formData.append('transfer', this.imageBukti, this.imageBukti.name)
    //   formData.append('user_vendor_id', this.user.id)

    //   axios.put(this.url + '/api/v1/vendor/upload/' + this.idPending + '/transfer', formData, {})
    //     .then((res) => {
    //       this.$buefy.toast.open({
    //         duration: 5000,
    //         message: 'Upload Berhasil, Tunggu approve dari admin',
    //         position: 'is-bottom',
    //         type: 'is-info'
    //       })
    //       this.clearInput()
    //       this.bayarPendingShow = false
    //       this.isLoading = false
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // },
    // filterLaporan (val, page) {
    //   this.paginate.loading = true
    //   const pr = '/api/v1/vendor/report/topup-nominal/pending?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&limit=10&page=' + page
    //   axios.get(this.url + pr)
    //     .then((cb) => {
    //       this.paginate.data = cb.data.data
    //       this.paginate.total = cb.data.total_page
    //       this.paginate.page = cb.data.current_page
    //       this.paginate.loading = false
    //     })
    // },
    getData () {
      this.paginate.loading = true
      const pr = '/api/v1/vendor/static-va'
      axios.get(this.url + pr)
        .then((cb) => {
          console.log(cb.data.data)
          this.paginate.data = cb.data.data
          this.paginate.total = cb.data.total_page
          this.paginate.page = cb.data.current_page
          this.paginate.loading = false
        })
    }
  },
  props: {
    date: Object
  },
  created () {
    this.getData()
  }
}
</script>
