<template>
  <div>
    <div class="top-info" :class="filterCompany()">
      <section>
        <div class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column mb-2">
                <div class="columns p-2">
                  <div class="column is-3">
                    <b-button
                      :class="invGabungan"
                      style="margin-top: 1.85rem;"
                      @click="getDataGabungan()"
                      icon-left="book"
                      type="is-success"
                      :disabled="!hasSelected"> Import Invoice Summary
                    </b-button>
                  </div>
                  <div class="column is-3">
                    <b-field label="Search">
                      <b-input placeholder="Search"
                        v-model="search"
                        type="search"
                        icon="magnify"
                        icon-clickable
                        @keyup.native.enter="cariInvoice"
                        @icon-click="cariInvoice">
                      </b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Filter">
                      <b-select placeholder="Select Company" icon="account" v-model="selectedClient">
                        <optgroup label="Company">
                          <option v-for="(item, index) in this.client" :value="item.id" :key="index">{{item.name}}</option>
                        </optgroup>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-two-fifths">
                    <b-button style="margin-top: 2rem;" @click="onFilterCompany(date, 1)" type="is-info" size="is-small"> Submit </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>

    <div>
      <b-table
        :checked-rows.sync="checkedRows"
        :striped="true"
        :hoverable="true"
        :total="total"
        :loading="isLoading"
        :paginated="paginated"
        :per-page="perPage"
        :data="invoiceData"
        default-sort="id"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-pagination>

        <b-table-column field="id" label="#" v-slot="props">
          <b-checkbox v-model="props.row.isSelect" />
        </b-table-column>
        <b-table-column label="#Id" field="id" v-slot="props">
          <small class="has-text-gray is-abbr-like" :title="props.row.jenis"> {{ props.row.jenis }} </small>
        </b-table-column>
        <b-table-column label="Client Name" field="company_name" sortable v-slot="props">
          <small class="has-text-weight-bold is-abbr-like" :title="props.row.company_name">{{ props.row.company_name }}</small>
        </b-table-column>
        <b-table-column label="PIC" field="consumer_name" v-slot="props">
          <small class="is-abbr-like">{{ props.row.consumer_name }}</small>
        </b-table-column>
        <b-table-column label="Tanggal Pengerjaan" field="start_date" sortable v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.start_date">{{ props.row.start_date }}</small>
        </b-table-column>
        <b-table-column label="Total Biaya Parkir" field="fee_charge_building" v-slot="props"><small>Rp.{{ props.row.fee_charge_building | numFormat }}</small></b-table-column>
        <b-table-column label="Total Pph" field="pph" v-slot="props"><small>{{ props.row.pph | numFormat }}</small></b-table-column>
        <b-table-column field="total_biaya" label="Total Biaya" v-slot="props">
          <small>Rp.{{ parseInt(props.row.total_price) - parseInt(props.row.pph) | numFormat }}</small>
        </b-table-column>
        <b-table-column field="project_status" label="status" v-slot="props">
          <small :class="{'has-text-info is-abbr-like': props.row.project_status === 'Active',
            'has-text-primary is-abbr-like': props.row.project_status === 'Mencari Mitra',
            'has-text-danger is-abbr-like': props.row.project_status === 'Cancelled'}">
            {{ props.row.project_status }}
          </small>
        </b-table-column>
        <b-table-column field="export_pdf" label="Action" v-slot="props">
          <b-button type="is-info" @click="viewmodal(props.row.project_id)">Preview PDF</b-button>
        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="account-alert-outline" size="is-large"/>
              </p>
              <p>No data here, Select date first&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </div>

    <div>
      <b-modal v-model="modalPreviewPdf" trap-focus aria-modal no-close-on-backdrop>
        <div class="card">
          <pdf-invoice-print :project="this.projectId"></pdf-invoice-print>
        </div>
      </b-modal>
    </div>

  </div>

</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'
import PdfInvoicePrint from './InvoicePdf.vue'

export default {
  name: 'laporanInvoiceProyek',
  data () {
    return {
      search: null,
      projectId: null,
      modalPreviewPdf: false,
      linkInvoice: '',
      fileName: 'invoice-project' + this.date,
      activeTab: 0,
      cabangSelected: null,
      dateRange: [],
      infoState: '',
      total: 0,
      isLoading: false,
      paginated: false,
      page: 1,
      perPage: 10,
      invoiceData: [],
      selectedClient: null,
      checkedRows: []
    }
  },
  components: {
    PdfInvoicePrint
  },
  props: {
    date: Object
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    },
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.getData(1)
      }
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      client: 'setup/getClient'
    }),
    sumOfPriceSesi () {
      for (var i = 0; i > this.invoiceData.length; i++) {
        console.log(this.sesi)
      }
      return this.sesi
    },
    hasSelected () {
      const t = this.invoiceData.find(o => o.isSelect === true)
      return t
    },
    invGabungan () {
      const arrList = []
      this.invoiceData.forEach((row) => {
        if (row.isSelect) {
          arrList.push(JSON.parse(row.project_id))
        }
      })

      if (arrList.length >= 2) {
        return 'is-show'
      }
      return 'is-hidden'
    }
  },
  methods: {
    cariInvoice () {
      if (this.search === null) { return }
      this.isLoading = true
      axios.get(this.url + '/api/v1/vendor/report/invoice-project?start_date=' + this.date.tgl1 + '&end_date=' + this.date.tgl2 + '&keyword=' + this.search + '&page=1&limit=1000')
        .then((val) => {
          this.isLoading = false
          if (val) {
            this.paginated = true
            this.invoiceData = val.data.data.list
            this.total = (val.data.data.total_page * this.perPage)
            this.page = val.data.data.current_page
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getDataGabungan () {
      this.$buefy.dialog.confirm({
        message: 'anda yakin ingin generate data ini?',
        onConfirm: () => {
          const invGabunganId = []
          this.invoiceData.forEach((row) => {
            if (row.isSelect) {
              invGabunganId.push(JSON.parse(row.project_id))
            }
          })

          axios.post(this.url + '/api/v1/vendor/invoices', { project_ids: invGabunganId })
            .then((cb) => {
              // const invSum = {
              //   url: this.url,
              //   ids: cb.data.data.id
              // }
              // this.$store.dispatch('invoice/loadInvSum', invSum)
              this.$buefy.toast.open({
                duration: 3000,
                message: cb.data.message,
                position: 'is-bottom',
                type: 'is-success'
              })
            })
            .catch((error) => {
              this.$buefy.toast.open({
                duration: 3000,
                message: error.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
              return false
            })
        }
      })
    },
    filterCompany () {
      if (this.date !== null) {
        return 'is-show'
      }
      return 'is-hidden'
    },
    previewReport (valId) {
      this.viewmodal(valId)
    },
    viewmodal (valId) {
      if (valId !== null) {
        this.modalPreviewPdf = !this.modalPreviewPdf
        this.projectId = valId
      }
    },
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    convDate (val) {
      return moment(val).format('DD MMMM YYYY hh:mm')
    },
    getData (val, page) {
      this.isLoading = true
      this.page = page
      const pr = '/api/v1/vendor/report/invoice-project?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&page=' + this.page + '&limit=' + this.perPage
      axios.get(this.url + pr)
        .then((cb) => {
          this.isLoading = false
          if (cb) {
            this.paginated = true
            this.invoiceData = cb.data.data.list
            this.total = (cb.data.data.total_page * this.perPage)
            this.page = cb.data.data.current_page
          }
        })
    },
    onFilterCompany (date, page) {
      this.isLoading = true
      this.page = page
      const pr = '/api/v1/vendor/report/invoice-project?start_date=' + date.tgl1 + '&end_date=' + date.tgl2 + '&page=' + this.page + '&limit=' + this.perPage + '&client_id=' + this.selectedClient
      axios.get(this.url + pr)
        .then((cb) => {
          this.isLoading = false
          if (cb) {
            this.paginated = true
            this.invoiceData = cb.data.data.list
            this.total = (cb.data.data.total_page * this.perPage)
            this.page = cb.data.data.current_page
          }
        })
    }
  },
  created () {},
  mounted () {
    this.filterCompany()
  }
}
</script>

<style lang="scss" scoped>
.top-info {
  border-bottom: solid thin #f0f0f0;
}
.is-hidden {
  visibility: hidden;
}
.is-show {
  visibility: visible;
}
</style>
