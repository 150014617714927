<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="invObject ? invObject.invoice : null"
      @cancel="invCancelRemove"
      @confirm="invConfrimRemove"
    />
    <b-modal v-model="modalPreviewPdf" trap-focus aria-modal no-close-on-backdrop>
      <div class="card">
        <pdf-invoice-print :invoice="this.invId"></pdf-invoice-print>
      </div>
    </b-modal>

    <b-table
      :checked-rows.sync="checkedRows"
      :striped="true"
      :hoverable="true"
      :total="total"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :data="dataListSum"
      default-sort="id"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-pagination>

      <b-table-column label="#Id" field="id" v-slot="props">
        <small class="has-text-gray is-abbr-like" :title="props.row.id"> {{ props.row.id }} </small>
      </b-table-column>
      <b-table-column label="#Reference No" field="invoice" v-slot="props">
        <small class="has-text-weight-bold is-abbr-like" :title="props.row.invoice">{{ props.row.invoice }}</small>
      </b-table-column>
      <b-table-column label="Creator" field="creator" v-slot="props">{{ props.row.creator }}</b-table-column>
      <b-table-column label="Created Date" field="created_at" sortable v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.created_at">{{ props.row.created_at }}</small>
      </b-table-column>
      <b-table-column field="export_pdf" label="Action" v-slot="props">
        <b-button type="is-info" @click.prevent="previewInv(props.row)">Preview PDF</b-button> &nbsp;
        <b-button @click.prevent="removeCurrentInv(props.row)" icon-left="delete" size="is-small" type="is-danger" outlined />
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="account-alert-outline" size="is-large"/>
            </p>
            <p>No data here's&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import ModalBox from '@/components/ModalBox'
import PdfInvoicePrint from './InvoicePdfSummary.vue'

export default {
  name: 'InvoiceSummaryList',
  components: { ModalBox, PdfInvoicePrint },
  data () {
    return {
      invId: null,
      isLoading: false,
      page: 1,
      perPage: 10,
      total: 0,
      dataListSum: [],
      paginated: false,
      isModalActive: false,
      trashObject: null,
      checkedRows: [],
      modalPreviewPdf: false,
      invObject: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      client: 'setup/getClient'
    }),
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    }
  },
  methods: {
    previewInv (invObject) {
      if (invObject !== null) {
        this.modalPreviewPdf = !this.modalPreviewPdf
        this.invId = invObject.id
      }
    },
    onPageChange (v) { this.getDataInvSum(v) },
    getDataInvSum (page) {
      this.isLoading = true
      this.page = page
      const pr = this.url + '/api/v1/vendor/invoices?page=' + this.page + '&limit=' + this.perPage
      axios.get(pr)
        .then((cb) => {
          this.isLoading = false
          if (cb) {
            this.paginated = true
            this.dataListSum = cb.data.data.data
            this.total = (cb.data.data.pageTotal * this.perPage)
          }
        })
    },
    removeCurrentInv (obj) {
      if (obj !== null) {
        this.isModalActive = !this.isModalActive
        this.invObject = obj
      }
    },
    invCancelRemove () {
      this.isModalActive = false
    },
    invConfrimRemove () {
      const pr = '/api/v1/vendor/invoices/' + this.invObject.id
      axios.delete(this.url + pr)
        .then((cb) => {
          if (cb) {
            this.isModalActive = false
            this.getDataInvSum(1)
            this.$buefy.snackbar.open({
              message: cb.data.message,
              queue: false
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted () {},
  created () {
    this.getDataInvSum(1)
  }
}
</script>
