/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import numeral from 'numeral'
import VCalendar from 'v-calendar'
import JsonExcel from 'vue-json-excel'
import Geocoder from '@pderas/vue2-geocoder'
import numFormat from 'vue-filter-number-format'
import * as VueGoogleMaps from 'vue2-google-maps'
import Autocomplete from '@trevoreyre/autocomplete-vue'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'

/* Default title tag */
const defaultDocumentTitle = 'Shell B2B Kanggo'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

axios.defaults.headers.common['API-KEY'] = 'vDt8loYSe9L4/Jrs9Fq5QUUSXqwGvqNEOlM52mL9OOl3SUREpAfHg8Gbi5XagRKJ'
axios.defaults.headers.Authorization = 'cVZi/z3dloDjK3Vv9XUi9s8vtUKk+fabzHIb1sbHQ2+XFVbBSrzNoFNiBEApOYQ23f3kovderH8wWol4SYtt21t4TN24So9ywfwv9TW0iHH3rSW2y39c577olQX1hzapZ/mPvc3vOlKOTj7CaZ7HbULyDzlRNO/+GcN/NNh7YfE='
const h = window.location.host
if (h === 'shellb2b.prodkanggo.com') {
  store.commit('setup/setUrl', 'https://bsdb2b-api.prodkanggo.com')
}
// if (h === 'shellb2b.devkanggo.com') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
// }
if (h.substring(0, 9) === 'localhost') {
  store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
}
// if (h === '192.168.1.17:8080') {
//   store.commit('setup/setUrl', 'https://bsdb2b-api.devkanggo.com')
// }

Vue.use(Buefy)
Vue.use(JsonExcel)
Vue.component('downloadExcel', JsonExcel)
Vue.filter('numFormat', numFormat(numeral))
Vue.use(VCalendar)
Vue.use(Autocomplete)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCBCNOKmd3VrxQaaPqxgNuGgfuZ1Idjryg',
    libraries: 'places'
  }
})
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: 'AIzaSyCBCNOKmd3VrxQaaPqxgNuGgfuZ1Idjryg'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
