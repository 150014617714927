<template>
  <div>
    <div class="columns mt-2">
      <div class="column">
        <b-button @click="tambahSkillOnClick()" type="is-success" icon-left="plus">Tambah Skill</b-button>
      </div>
    </div>
    <div class="column is-12">
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <b-table-column field="id" label="#" v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="skill_name" label="Skill" v-slot="props">
          <div style="display: flex;">
            <div>
              <img :src="props.row.image_uri" style="width: 50px;" />
            </div>
            <div style="margin-left: 10px; margin-top: 12px;">
              {{ props.row.name }}
            </div>
          </div>
        </b-table-column>
        <b-table-column field="act" label="Action" v-slot="props">
          <b-button @click="lihatDetailOnClick(props.row)" type="is-success" style="margin-right: 5px;">Lihat Detail</b-button>
        </b-table-column>
      </b-table>
    </div>

    <!-- Modal Save & Update Sesi Skill -->
    <b-modal
      v-model="newIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah Skill</p>
            <button type="button" class="delete" @click="newIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Pilih Skills">
              <div v-if="currentSkill === null">
                <b-dropdown
                  scrollable
                  mobile-modal
                  max-height="350px"
                  @change="skillIsSelected"
                  v-model="currentSkill"
                  aria-role="list">
                  <template #trigger>
                    <b-button label="Pilih skills yang anda butuhkan" type="is-light" icon-right="menu-down" />
                  </template>
                  <b-dropdown-item custom aria-role="listitem">
                    <b-input v-model="searchSkill" placeholder="search" expanded />
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(menu, index) in filteredDataSkill"
                    :key="index"
                    :value="menu" aria-role="listitem">
                    <div class="media">
                      <img :src="menu.image_uri" class="media-left" style="width: 25px;" />
                      <div class="media-content">
                        <h3>{{menu.name}}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-if="currentSkill !== null" style="border: solid thin grey; border-radius: 5px; padding: 10px;">
                <div class="media">
                  <img :src="currentSkill.image_uri" class="media-left" style="width: 25px;" />
                  <!-- <b-icon class="media-left" icon="shopping-search"></b-icon> -->
                  <div class="media-content">
                      <h3>{{currentSkill.name}}</h3>
                  </div>
                </div>
              </div>
            </b-field>
            <b-field label="Sesi Pengerjaan" style="margin-top: 20px;">
              <b-button @click="pilihSesiOnClick()" expanded type="is-success" label="Pilih Sesi" />
            </b-field>
            <table class="table is-size-6 is-size-7-mobile" style="width: 100%;">
              <thead>
                <tr>
                  <td></td>
                  <td>Nama Sesi</td>
                  <td>Jam Mulai - Jam Selesai</td>
                  <td>Harga</td>
                  <td></td>
                </tr>
              </thead>
              <tbody v-if="currentSkill !== null">
                <tr v-for="(v, i) in currentSkill.sesi" :key="i">
                  <td></td>
                  <td> {{ v.name }} </td>
                  <td>{{ v.start_hour }} - {{ v.end_hour }}</td>
                  <td>Rp.{{ v.price | numFormat}}</td>
                  <td>
                    <b-button @click="removeCurrentSkill(i)" icon-left="delete" size="is-small" type="is-danger" outlined />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer class="modal-card-foot">
            <b-button :loading="isLoading" @click="saveSkill()" label="Simpan" type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

    <!-- Modal Add Sesi -->
    <b-modal
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      v-model="sesiIsShow">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pilih Sesi</p>
          <button type="button" class="delete" @click="sesiIsShow = false"/>
        </header>
        <section class="modal-card-body">
          <table class="table table-container">
            <thead>
              <tr>
                <td></td>
                <td>Nama Sesi</td>
                <td>Jam Mulai - Jam Selesai</td>
                <td>Harga</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, i) in sesi" :key="i">
                <td> <b-checkbox v-model="v.isSelect" /></td>
                <td>{{ v.name }}</td>
                <td>{{ v.start_hour }} - {{ v.end_hour }}</td>
                <td>Rp.{{ v.price | numFormat }}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="simpanSesiOnClick()" type="is-success" label="Simpan"/>
        </footer>
      </div>
    </b-modal>

    <!-- Modal Detail -->
    <b-modal
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      v-model="detailSkillIsShow">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Detail Sesi:
            <span class="has-text-weight-semibold">{{ nameSkill }}</span>
          </p>
          <button type="button" class="delete" @click="detailSkillIsShow = false"/>
        </header>
        <section class="modal-card-body">
          <table v-if="skillSelectedDetail !== null" class="table is-size-6 is-size-7-mobile">
            <thead>
              <tr>
                <td></td>
                <td>Nama Sesi</td>
                <td>Jam Mulai - Jam Selesai</td>
                <td>Harga</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, i) in skillSelectedDetail.sesi" :key="i">
                <td></td>
                <td>{{ v.name }}</td>
                <td>{{ v.start_hour }} - {{ v.end_hour }}</td>
                <td>Rp.{{ v.price | numFormat }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'PengaturanSkill',
  data () {
    return {
      searchSkill: '',
      nameSkill: '',
      sesiIsShow: false,
      detailSkillIsShow: false,
      currentSkill: null,
      skillSelectedDetail: null,
      search: null,
      newIsShow: false,
      isLoading: false,
      name: null,
      startHour: null,
      endHour: null,
      price: null,
      sesiSelected: null,
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      },
      skills: [],
      sesi: [],
      listSesiSelected: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredDataSkill () {
      const search = this.searchSkill.toLowerCase()
      return this.skills.filter(item => typeof item.name === 'string' && item.name.toLowerCase().includes(search))
    }
  },
  components: {},
  watch: {
    search (newVal) {
      if (newVal === null || newVal === '') {
        this.loadData(1)
      }
    }
  },
  methods: {
    close () {
      this.detailSkillIsShow = false
    },
    clearInput () {},
    saveSkill () {
      if (this.currentSkill === null || this.listSesiSelected === []) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Data Tidak Boleh Kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      const sesiId = []
      this.currentSkill.sesi.forEach((row) => {
        sesiId.push(row.id)
      })
      const data = {
        skill_id: this.currentSkill.id,
        sesi_id: sesiId
      }
      this.isLoading = true
      axios.post(this.url + '/api/v1/vendor/skill/add-sesi', data)
        .then(() => {
          this.loadData(1)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Save sesi berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.newIsShow = false
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error save',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    deleteSesiDetail (id) {
      // this.sesiSelected = val
      const sesiId = []
      this.removeCurrentSkill.forEach((row) => {
        sesiId.push(row.id)
      })
      const data = {
        sesi_id: sesiId
      }
      this.isLoading = true
      axios.post(this.url + '/api/v1/vendor/skill/' + id + '/delete-sesi', data)
        .then(() => {
          this.loadData(1)
          this.$buefy.toast.open({
            duration: 5000,
            message: 'delete sesi berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearInput()
          this.newIsShow = false
          this.isLoading = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error update',
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.isLoading = false
        })
    },
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    onPageChange (v) {
      this.loadData(v)
    },
    loadSkills () {
      axios.get(this.url + '/api/v1/vendor/skill?page=1&limit=1000').then((cb) => {
        if (cb) {
          this.skills = cb.data.data
        }
      })
    },
    loadSesi () {
      axios.get(this.url + '/api/v1/vendor/sesi?page=&limit=1000')
        .then((data) => {
          if (data) {
            this.sesi = []
            data.data.data.forEach((row) => {
              const _row = JSON.parse(JSON.stringify(row))
              _row.isSelect = false
              this.sesi.push(_row)
            })
            // this.sesi = data.data.data
          }
        })
    },
    simpanSesiOnClick () {
      this.sesi.forEach((row) => {
        if (row.isSelect) {
          const _row = JSON.parse(JSON.stringify(row))
          this.listSesiSelected.push(_row)
          this.currentSkill.sesi.push(_row)
        }
      })
      this.sesiIsShow = false
    },
    loadData (page) {
      this.paginate.loading = true
      this.paginate.page = page
      const url = this.url + '/api/v1/vendor/skill?page=' + this.paginate.page + '&limit=' + this.paginate.perPage
      axios.get(url)
        .then((data) => {
          if (data) {
            this.paginate.data = data.data.data
            this.paginate.total = (data.data.total_page * this.paginate.perPage)
            this.paginate.page = data.data.current_page
            this.paginate.loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    skillIsSelected (val) {},
    tambahSkillOnClick () {
      this.newIsShow = true
      this.currentSkill = null
    },
    pilihSesiOnClick () {
      this.sesiIsShow = true
      this.listSesiSelected = []
      this.sesi.forEach((row) => {
        row.isSelect = false
      })
    },
    lihatDetailOnClick (row) {
      this.detailSkillIsShow = true
      if (row) {
        this.skillSelectedDetail = row
        this.nameSkill = row.name
      }
    },
    removeCurrentSkill (index) {
      this.currentSkill.sesi.splice(index, 1)
    }
  },
  created () {
    this.loadData(1)
    this.loadSesi(1)
    this.loadSkills()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
