import { render, staticRenderFns } from "./InvoiceProyekSum.vue?vue&type=template&id=947b4a62&"
import script from "./InvoiceProyekSum.vue?vue&type=script&lang=js&"
export * from "./InvoiceProyekSum.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports