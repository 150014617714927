<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      <router-link slot="right" to="/" class="button"> Dashboard </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <!-- <profile-update-form class="tile is-child"/> -->
        <card-component title="Profile" icon="account" class="tile is-child">
          <user-avatar class="image has-max-width is-aligned-center"/>
          <p class="has-text-centered has-text-weight-semibold mt-4">Role: {{user.role}}</p>
          <hr>
          <b-field label="Name">
            <b-input :value="user.name" custom-class="is-static" readonly/>
          </b-field>
          <hr>
          <b-field label="E-mail">
            <b-input :value="user.email" custom-class="is-static" readonly/>
          </b-field>
        </card-component>
        <password-update-form/>
      </tiles>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
// import ProfileUpdateForm from '@/components/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'Profile',
  components: { UserAvatar, Tiles, PasswordUpdateForm, HeroBar, TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Profile'
      ]
    },
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  }
}
</script>
