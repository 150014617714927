<template>
  <div>
    <header class="card-header">
      <p v-if="productSelected == null" class="card-header-title">Nama Produk</p>
      <p class="card-header-title" v-if="productSelected !== null">
        <b-button icon-left="arrow-left" @click="backToSearch()" style="margin-right: 10px;" />
        Pilih Layanan
      </p>
    </header>
    <div v-if="productSelected == null" class="card-content" style="height: 350px;">
      <b-field>
        <autocomplete
          placeholder="Cari data produk"
          aria-label="Cari data produk"
          :get-result-value="getResultValue"
          @submit="handleResultValue"
          :search="searchProducts"
          :debounce-time="500">
          <template
            #result="{ result, props }">
            <li v-bind="props">
              <div style="display: flex;">
                <div> <img style="width: 60px; border-radius: 5px;" :src="result.iamge_uri" /> </div>
                <div style="padding-left: 20px; padding-top: 10px;"> {{ result.name }} </div>
              </div>
            </li>
          </template>
        </autocomplete>
      </b-field>
    </div>
    <div class="card-content">
      <div v-if="productSelected !== null">
        <div style="display: flex; border-bottom: solid thin hsl(0, 0%, 21%); padding-bottom: 20px; margin-bottom: 20px; align-items: center; justify-content: center;">
          <div> <img style="width: 60px; border-radius: 5px;" :src="productSelected.iamge_uri" /> </div>
          <div style="padding-left: 20px; padding-top: 10px;">
            <strong>{{ productSelected.name }}</strong>
          </div>
        </div>
        <div v-if="productDetailSelected !== null">
          <table style="width: 100%">
            <tr style="background-color: #cde3fc">
              <td style="padding-left: 10px">Layanan</td>
              <td>Penugasan Tukang</td>
              <td>Action</td>
            </tr>
            <tr v-for="(rw, i) in productDetailSelected.service_list" :key="i" style="border-bottom: solid thin black">
              <td class="pt-3 pb-3 pl-2">{{ rw.name }}</td>
              <td style="padding-top: 20px; padding-bottom: 20px;">
                <ul class="is-size-7">
                  <li v-for="(rw, i) in rw.skill_list" :key="'A' + i">
                    <div style="margin-bottom: 12px;"> {{ rw.name }} </div>
                  </li>
                </ul>
              </td>
              <td style="padding-top: 20px; padding-bottom: 20px">
                <b-button @click="pilihLayanan(rw)" type="is-success" size="is-small">Pilih Layanan</b-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductList',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
    // disabled () {
    //   if (this.productSelected.id) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
  },
  methods: {
    backToSearch () {
      this.productSelected = null
    },
    // getResultId (val) {
    //   return val.id
    // },
    getResultValue (val) {
      return val.name
    },
    handleResultValue (val) {
      this.productSelected = val
      this.searchproductDetails(val.id)
      this.productId = val.id
    },
    searchproductDetails (productId) {
      const _search = this.url + '/api/v1/vendor/product_detail_sesi?product_id=' + productId
      axios.get(_search)
        .then((cb) => {
          this.productDetailSelected = cb.data.data
        })
    },
    searchProducts (val) {
      const _search = this.url + '/api/v1/vendor/product_list?search_text=' + val
      return new Promise((resolve) => {
        if (val.length < 1) { return resolve([]) }
        axios.get(_search)
          .then((cb) => {
            resolve(cb.data.data)
          })
          .catch(() => {
            resolve([])
          })
      })
    },
    tambahBarang (row) {
      this.$emit('productonclick', row)
    },
    pilihLayanan (val) {
      const ls = []
      val.skill_list.forEach((rw) => {
        ls.push({
          id: rw.id,
          image_uri: rw.image_uri,
          name: rw.name,
          order_tukang: 1
        })
      })
      const data = {
        product: this.productSelected,
        service: val,
        skills: ls
      }
      this.$emit('productonclick', data)
    }
  },
  mounted () {},
  data () {
    return {
      productId: null,
      products: [],
      productSelected: null,
      productDetailSelected: null
    }
  }
}
</script>
