<template>
    <div>
      <modal-box :is-active="isModalActive" :trash-object-name="trashObjectName" @confirm="trashConfirm" @cancel="trashCancel"/>
      <b-table
        :checked-rows.sync="checkedRows"
        :striped="true"
        :hoverable="true"
        :total="total"
        :loading="isLoading"
        :paginated="paginated"
        :per-page="perPage"
        :data="clients"
        default-sort="id"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-pagination>

        <b-table-column label="#ID Proyek" field="id" v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.id">{{ props.row.id }}</small>
        </b-table-column>
        <b-table-column label="lokasi" field="name" v-slot="props">
          {{ props.row.address }}
        </b-table-column>
        <b-table-column label="Mulai" field="start_date" sortable v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.start_date">{{ props.row.start_date }}</small>
        </b-table-column>
        <b-table-column label="Selesai" v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.end_date">{{ props.row.end_date }}</small>
        </b-table-column>
        <b-table-column label="Jenis Pesanan" v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.type">{{ props.row.type }}</small>
        </b-table-column>
        <b-table-column label="Status" v-slot="props">
          <small class="has-text-grey is-abbr-like" :title="props.row.status">
            <span :class="{'tag is-link': props.row.status === 'In Planning'}">{{ props.row.status }}</span>
          </small>
        </b-table-column>
        <b-table-column label="Action" custom-key="actions" cell-class="is-actions-cell" v-slot="props">
          <div class="buttons is-right">
            <div class="button is-small is-primary" @click="detailOnClick(props.row.id)">
              <b-icon icon="account-edit" size="is-small"/>
            </div>
          </div>
        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's here&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </div>
  </template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'ProjectTablePlan',
  components: { ModalBox },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      clients: [],
      isLoading: false,
      paginated: false,
      perPage: 5,
      total: 0,
      page: 1,
      checkedRows: []
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    },
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  },
  mounted () {
    if (this.url) {
      this.loadDataTable(1)
    }
  },
  methods: {
    detailOnClick (v) {
      this.$router.push({
        path: '/project/detail/' + v + '/Dalam-Rencana'
      })
    },
    onPageChange (v) {
      this.loadDataTable(v)
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    loadDataTable (page) {
      this.isLoading = true
      this.page = page
      const urlTableProyek = this.url + '/api/v1/vendor/project_list?status=' + 'In Planning' + '&page=' + this.page + '&limit=' + this.perPage
      axios.get(urlTableProyek).then((r) => {
        this.isLoading = false
        if (r.data.data.list) {
          this.paginated = true
          this.clients = r.data.data.list
          this.total = (r.data.data.total_page * this.perPage)
        }
      }).catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    }
  }
}
</script>
