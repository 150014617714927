<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="buttons is-left">
            <p @click="kembaliKeProyek()" class="subtitle is-6">
              <b-icon icon='arrow-left'/> Kembali ke Beranda Proyek
            </p>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <ul>
            <li v-for="(title,index) in titleStack" :key="index">{{ title }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    kembaliKeProyek () {
      this.$emit('clicked', 'project tukang langsung?')
    }
  }
}
</script>
