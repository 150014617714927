<template>
  <div style="margin-bottom: 40px;">
    <div>
      <section style="margin-bottom: 20px; padding: 20px 10px 0px 10px; display: flex; justify-content: space-between;">
        <div>
          <p @click="back()" class="back-btn title is-6 clickable" style="margin-bottom: 10px;"> <b-icon icon='arrow-left'/> Kembali ke Daftar Produk</p>
          <h1 style="padding-left: 10px;" class="title is-4">Detail Produk</h1>
        </div>
        <div>
          <b-button @click="deleteProduct()" type="is-danger">Hapus Produk</b-button>
        </div>
      </section>
      <hr/>
      <div v-if="product !== null" class="container is-max-desktop">
        <h1 class="title is-5">Info Produk</h1>
        <div class="columns">
          <div class="column">
            <img :src="product.image_uri" style="width: 100px; border-radius: 20px;" />
          </div>
          <div class="column is-two-thirds title is-6">
            {{ product.name }}
          </div>
          <div class="column">
            <b-button @click="ubahProdukOnClick()" type="is-success">Ubah</b-button>
          </div>
        </div>
        <div style="margin-top: 30px;">
          <div style="display: flex; justify-content: space-between;">
            <div>
              <h1 class="title is-5">Detail Layanan</h1>
            </div>
            <div>
              <b-button @click="tambahIsShow = true" type="is-success" icon-left="plus">Tambah Layanan</b-button>
            </div>
          </div>
          <table style="width: 100%; margin-top: 20px;">
            <tr v-for="(v, i) in product.service_list" :key="i">
              <td style="width: 5%; text-align: center;">
                {{ i + 1 }}
              </td>
              <td style="width: 80%;">
                <strong>{{ v.name }}</strong>
                <div style="display: flex; width: 100%; margin-top: 10px;">
                  <div style="width: 50%;">
                    <strong>Penugasan Tukang:</strong>
                    <ul>
                      <li v-for="(x, ii) in v.skill_list" :key="`x_`+ii">
                        {{ x.name }}
                      </li>
                    </ul>
                  </div>
                  <!-- <div style="width: 50%;">
                    <strong>Biaya:</strong>
                    <ul>
                      <li v-for="(x, ii) in v.price" :key="`xb_`+ii">
                        Rp. {{ x.price | numFormat }} /
                        <span v-if="x.sesi === 0">seharian</span>
                        <span v-if="x.sesi === 1">sesi pagi</span>
                        <span v-if="x.sesi === 2">sesi sore</span>
                      </li>
                    </ul>
                  </div> -->
                </div>
              </td>
              <td style="width: 15%;">
                <b-button @click="editLayanan(v)" style="margin-right: 5px;" type="is-success" icon-left="pencil-outline" />
                <b-button @click="deleteLayanan(v)" type="is-danger" icon-left="delete" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      v-model="tambahIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 500px; height: 600px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Tambah Layanan</p>
            <button
              type="button"
              class="delete"
              @click="tambahIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Layanan">
              <b-input
                v-model="name"
                placeholder="Masukan nama layanan disini"
                required>
              </b-input>
            </b-field>
            <b-field label="Penugasan Tukang">
              <multiselect v-model="penugasanTukangSelected"
                tag-placeholder="Tambah penugasan tukang"
                placeholder="Cari dan tambah penugasan tukang"
                label="name"
                track-by="id"
                :searchable="false"
                :options="penugasanTukang"
                :multiple="true"
                :taggable="true"
                @tag="addTag"></multiselect>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              :loading="isLoading"
              @click="saveServices()"
              label="Simpan"
              type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="editProdukIsShow"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <form action="">
        <div class="modal-card" style="width: 500px; height: 600px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Edit Info</p>
            <button
              type="button"
              class="delete"
              @click="editProdukIsShow = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Nama Produk">
              <b-input
                v-model="produk.name"
                placeholder="Masukan nama produk disini"
                required>
              </b-input>
            </b-field>
            <b-field label="Foto Produk">
              <b-input
                v-model="produk.image_uri"
                placeholder="Masukan URL foto produk disini"
                required>
              </b-input>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              :loading="isLoading"
              @click="saveUbahProduk()"
              label="Simpan"
              type="is-success" />
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PengaturanProductDetail',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {
    Multiselect
  },
  methods: {
    back () {
      this.$router.push({ path: '/pengaturan' })
    },
    addTag (val) {
      this.penugasanTukangSelected.push(val)
    },
    loadData (productId) {
      axios.get(this.url + '/api/v1/vendor/product_detail?product_id=' + productId)
        .then((cb) => {
          this.product = cb.data.data
        })
    },
    getSkills () {
      axios.get(this.url + '/api/v1/vendor/common-data/skill_list')
        .then((cb) => {
          this.penugasanTukang = cb.data.data
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error load skill lists',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    clearData () {
      this.name = null
      this.penugasanTukangSelected = []
      this.isEdit = false
      this.price0 = 0
      this.price1 = 0
      this.price2 = 0

      this.produk = {
        name: null,
        image_uri: null
      }
    },
    editLayanan (layanan) {
      this.layananSelected = layanan
      this.setEditService(layanan)
      this.tambahIsShow = true
    },
    deleteProduct () {
      this.$buefy.dialog.confirm({
        message: 'Hapus produk : ' + this.product.name,
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/product', {
            data: {
              product_id: this.product.id
            }
          })
            .then(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus layanan berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
              this.back()
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus layanan',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    deleteLayanan (layanan) {
      this.layananSelected = layanan
      this.$buefy.dialog.confirm({
        message: 'Hapus layanan : ' + this.layananSelected.name,
        onConfirm: () => {
          axios.delete(this.url + '/api/v1/vendor/service', {
            data: {
              service_id: this.layananSelected.id
            }
          })
            .then(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Hapus layanan berhasil!...',
                position: 'is-bottom',
                type: 'is-success'
              })
              this.clearData()
              this.loadData(this.product.id)
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'Error hapus layanan',
                position: 'is-bottom',
                type: 'is-danger'
              })
            })
        }
      })
    },
    setEditService (val) {
      this.isEdit = true
      this.name = val.name
      _.forEach(val.price, (row) => {
        if (row.sesi === 0) {
          this.price0 = row.price
        }
        if (row.sesi === 1) {
          this.price1 = row.price
        }
        if (row.sesi === 2) {
          this.price2 = row.price
        }
      })
      this.penugasanTukangSelected = val.skill_list
    },
    saveServices () {
      if (this.name === null || this.product === null || this.penugasanTukangSelected.length === 0) {
        return
      }
      this.isLoading = true
      const data = {
        product_id: this.product.id,
        service_name: this.name,
        skill_list: []
        // price_list: [
        //   {
        //     sesi: 0,
        //     price: this.price0
        //   },
        //   {
        //     sesi: 1,
        //     price: this.price1
        //   },
        //   {
        //     sesi: 2,
        //     price: this.price2
        //   }
        // ]
      }
      this.penugasanTukangSelected.forEach((r) => {
        data.skill_list.push(r.id)
      })
      if (this.isEdit === false) {
        axios.post(this.url + '/api/v1/vendor/service', data)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Simpan data berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.clearData()
            this.loadData(this.product.id)
            this.tambahIsShow = false
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error load skill lists',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      } else {
        data.service_id = this.layananSelected.id
        axios.put(this.url + '/api/v1/vendor/service', data)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Update data berhasil!...',
              position: 'is-bottom',
              type: 'is-info'
            })
            this.clearData()
            this.loadData(this.product.id)
            this.tambahIsShow = false
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Error load skill lists',
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }
    },
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    ubahProdukOnClick () {
      this.produk.name = this.product.name
      this.produk.image_uri = this.product.image_uri
      this.editProdukIsShow = true
    },
    saveUbahProduk () {
      if (this.produk.name === null || this.produk.image_uri === null) {
        return
      }
      this.isLoading = true
      const data = {
        product_id: this.product.id,
        product_name: this.produk.name,
        product_image: this.produk.image_uri
      }
      axios.put(this.url + '/api/v1/vendor/product', data)
        .then(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Simpan data berhasil!...',
            position: 'is-bottom',
            type: 'is-info'
          })
          this.clearData()
          this.loadData(this.product.id)
          this.editProdukIsShow = false
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Error load skill lists',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  },
  data () {
    return {
      product: null,
      tambahIsShow: false,
      isEdit: false,
      editProdukIsShow: false,
      produk: {
        name: null,
        image_uri: null
      },
      penugasanTukang: [],
      penugasanTukangSelected: [],
      name: null,
      isLoading: false,
      price0: 0,
      price1: 0,
      price2: 0,
      layananSelected: null
    }
  },
  mounted () {},
  created () {
    this.loadData(this.$route.params.productId)
    this.getSkills()
  }
}
</script>

<style lang="scss" scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
td {
  padding: 10px;
}

.clickable {
  cursor: pointer;
}
.dash-container {
  padding: 20px 0px 0px 0px;
}
.card-kanggo {
  background-color: white;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  font-size: 14px;
  table {
    tr {
      td {
        width: auto;
        border-bottom: solid thin rgb(240, 240, 240);
        height: 45px;
        padding: 10px 15px 5px 15px;
      }
    }
  }
}
.card-paginate {
  display: flex;
  font-size: 14px;
}
.tab-label {
  width: 150px;
  text-align: center;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  &.active {
    border-bottom: solid thin red;
    color: red;
    font-weight: bold;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
