<template>
  <div>
    <TitleBarProject :title-stack="titleStack" @clicked="kembaliKeProyek"/>
    <transition name="fade">
      <div v-show="!viewState" class="container is-max-desktop">
        <!-- Pilih Client -->
        <div class="input-card" style="margin-top: 20px;">
          <b-field label="Client">
            <b-select placeholder="Select Client" expanded v-model="clientSelect">
              <option v-for="(item, index) in clientOptions" :value="item" :key="index">{{item.name}}</option>
            </b-select>
          </b-field>
        </div>

        <!-- Card Pilih PIC -->
        <div class="input-card" style="margin-top: 20px;">
          <div><span class="has-text-weight-bold">PIC</span></div>
          <notification class="is-info mt-2">
            <span class="has-text-weight-bold">Jika sudah menentukan PIC,
              infokan jadwal tukang yang akan datang ke PIC yang telah dipilih!
            </span>
          </notification>
          <div class="columns" v-if="newProject && newProject.customer_name" style="padding-top: 10px;">
            <div class="column is-four-fifths">
              <h1 class="title is-5"> {{ newProject.customer_name }} ( {{ newProject.cell_phone }} ) </h1>
            </div>
            <div class="column">
              <b-button type="is-success" icon-left="account" @click="customerIsView=true">Ubah</b-button>
            </div>
          </div>
          <div style="text-align: center; margin-top: 30px;">
            <b-button v-if='!newProject || !newProject.customer_name' @click.prevent="customerIsView=true"
              type="is-success" style="width: 300px;" icon-left="account-alert-outline">
              Tentukan PIC
            </b-button>
          </div>
        </div>

        <!-- Card Pilih Mitra -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-weight-bold">Pilih Tukang</div>
              <div class="dropdown-box">
                <b-dropdown
                  append-to-body
                  scrollable
                  expanded
                  max-height="450px"
                  mobile-modal
                  @change="skillIsSelected"
                  v-model="currentSkill"
                  aria-role="list">
                  <template #trigger>
                    <b-button
                    label="Pilih tukang yang anda butuhkan"
                    type="is-light"
                    icon-right="menu-down"/>
                  </template>
                  <b-dropdown-item custom aria-role="listitem">
                    <b-input v-model="searchSkill" placeholder="search" expanded />
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="(item, index) in filteredDataSkill" :key="index" :value="item" aria-role="listitem">
                    <div class="media">
                      <img :src="item.image_uri" class="media-left" style="width: 50px;" />
                      <div class="media-content">
                        <h3>{{item.name}}</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="columns is-mobile mt-3 is-align-content-center" v-for="(lay, idx) in skillsSelected" :key="lay.id">
                <div class="column is-two-fifths-mobile">
                  <div class="media">
                    <img :src="lay.image_uri" class="media-left" style="width: 25%;" />
                    <div class="media-content">
                      <h3>{{ lay.name }}</h3>
                    </div>
                  </div>
                </div>
                <div class="column is-two-fifths-mobile">
                  <b-field>
                    <b-numberinput size="is-small" :min="1" v-model="lay.tukang" controls-rounded></b-numberinput>
                  </b-field>
                </div>
                <div class="column has-text-centered">
                  <b-button @click="deleteSkill(idx)" type="is-danger" icon-left="delete" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Card Deskripsi Pekerjaan -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold"> Deskripsi Pekerjaan </div>
          <div style="margin-top: 25px;">
            <b-input v-model="deskripsiPekerjaan" rows="2" maxlength="200" minlength="10" type="textarea"></b-input>
          </div>
        </div>

        <!-- Card Pilih Alamat -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="columns">
            <div class="column">
              <div class="has-text-weight-bold"> Alamat </div>
              <div style="display: flex;" v-if="newProject && newProject.address">
                <div style="padding-top: 20px; width: 60%;">
                  <div style="margin-right: 20px;" v-if="newProject && newProject.latitude && newProject.longitude">
                    <GmapMap class="googleMapsView" map-type-id="roadmap" :zoom="17" :center="map.currentPlace" :options="{disableDefaultUI: true}">
                      <GmapMarker :position="map.currentPlace">
                      </GmapMarker>
                    </GmapMap>
                  </div>
                  <div style="padding: 20px 20px 0px 0px;">
                    {{ newProject.address }}
                    <br/>
                    Bangunan: <strong>{{ newProject.jenis_bangunan }}</strong>
                  </div>
                </div>
                <div class="ml-5" style="width: 7em; padding-top: 20px;">
                  <b-button type="is-info" tag="input" native-type="submit" value="Ubah"
                    class="button is-outlined" @click="modal.tentukanAlamatIsView=true" expanded/>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div style="text-align: center; margin-top: 40px;">
                <b-button v-if="!newProject || !newProject.address" @click="modal.tentukanAlamatIsView=true"
                  type="is-success" style="width: 300px;" icon-left="map-marker"> Tentukan Alamat </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Card Pilih Jadwal -->
        <div class="input-card" style="margin-top: 20px;">
          <div class="has-text-weight-bold">Jadwal</div>
          <div class="level" v-if="newProject && newProject.detail_sesi.length > 0">
            <div class="level-left">
              <table class="table" style="margin-top: 20px;">
                <tbody class="is-size-6 is-size-7-mobile has-text-dark">
                  <tr v-for="(v, i) in newProject.detail_sesi" :key="i">
                    <td>{{ v.day }}</td>
                    <td>{{ convDate(v.date) }}</td>
                    <td>{{ v.skillsName }}</td>
                    <td>{{ v.start_hour }} - {{ v.end_hour }}</td>
                    <td>Rp.{{ v.price | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="level-right">
              <b-button type="is-info" tag="input" native-type="submit" value="Ubah" class="button is-outlined" @click="tentukanJadwalOnShow()" expanded/>
            </div>
          </div>
          <div v-if="newProject && newProject.detail_sesi.length === 0" style="text-align: center; margin-top: 40px;">
            <b-button @click="tentukanJadwalOnShow()" type="is-success" style="width: 300px;" icon-left="calendar-multiselect">
              Tentukan Jadwal
            </b-button>
          </div>
        </div>

        <!-- Card Biaya Parkir -->
        <div class="input-card" style="margin-top: 20px;">
          <b-field label="Total Biaya Parkir">
            <b-input type="number" v-model="biayaParkir" placeholder="Masukan Nominal"></b-input>
          </b-field>
        </div>

        <!-- Biaya PPh -->
        <div class="input-card" style="margin-top: 20px;">
          <b-field label="Biaya PPh">
            <b-checkbox size="is-small" v-model="isPph" value="1">
              <span class="is-size-6">Termasuk Biaya PPh (2%)</span>
            </b-checkbox>
          </b-field>
        </div>

        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button @click.prevent="createProjectConfirm()" type="is-primary" style="width: 300px;" icon-right="arrow-right">
            Lanjutkan
          </b-button>
        </div>
      </div>
    </transition>

    <!-- modal pilih customer -->
    <ModalBase :is-active="customerIsView" @cancel="closeModalPIC">
      <template v-slot:header> <h6 style="font-weight: bold">Tentukan PIC</h6> </template>
      <template v-slot:body>
        <project-customer @catchvalue="insertCustomer" />
      </template>
    </ModalBase>

    <!-- modal pilih alamat pengerjaan -->
    <b-modal
      v-model="modal.tentukanAlamatIsView"
      aria-role="dialog"
      @cancel="closeModalAlamat">
      <div class="card" style="background-color:#ffffff">
        <header class="card-header">
          <p class="card-header-title"> Tentukan Alamat </p>
        </header>
        <div class="card-content">
          <div class="content">
            <b-field label="Lokasi di Peta">
              <div class="columns is-mobile">
                <div class="column">
                  <b-button class="is-primary is-outlined m-2" @click="mapOnViewClick()" type="is-success" icon-left="map-marker-plus"> Pilih Lokasi </b-button>
                  <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
                    <GmapMap style="max-width: 100%; height: 200px;" map-type-id="roadmap" :zoom="15"
                      :center="map.currentPlace"
                      :options="{disableDefaultUI: true}">
                      <GmapMarker :position="map.currentPlace"></GmapMarker>
                    </GmapMap>
                  </div>
                </div>
              </div>
            </b-field>
            <b-field label="Alamat">
              <b-input class="has-text-black has-text-weight-bold" disabled v-model="modal.addressRef" minlength="10" maxlength="200" type="textarea"></b-input>
            </b-field>
            <b-field label="Deskripsi Lokasi">
              <b-input v-model="modal.address" minlength="10" maxlength="200" type="textarea"></b-input>
            </b-field>
            <b-field label="Jenis Bangunan">
              <div class="tile is-ancestor">
                <div class="tile is-parent" v-for="(v, i) in buildingType" :key="i">
                  <article class="tile is-child box" @click="setBuildingType(i)" :class="{'jenis-bangunan-selected': v.isSelect === true}">
                    <p class="subtitle">
                      <img class="image is-32x32 centerImgs" :src="v.image_uri" />
                    </p>
                    <p class="subtitle has-text-centered">{{ v.name }}</p>
                  </article>
                </div>
              </div>
            </b-field>
            <div class="content" style="text-align: right; margin-top: 15px;">
              <b-button @click="insertAddress()" style="width: 200px;"
                :type="{'is-light': modal.address === '' && map.currentPlace.lat === null,
                  'is-success' : modal.address !== '' && map.currentPlace.lat !== null }">
                Simpan
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- maps -->
    <b-modal v-model="modal.isMapOnView">
      <div class="card" style="background-color: #ffffff">
        <div class="card-content">
          <b-field label="Cari Alamat">
            <b-autocomplete
              :data="map.address"
              placeholder="Telusuri Alamat"
              field="title"
              :loading="map.isFetchingAddress"
              @typing="getAddress"
              @select="option => map.addressSelected = option">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> {{ props.option.address }} </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <GmapMap map-type-id="roadmap" :zoom="15"
            :center="map.gmapCenter"
            style="width: auto; height: 400px">
            <GmapInfoWindow :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
              <div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>
            </GmapInfoWindow>
            <GmapMarker :position="map.markerPosition" :clickable="true"
              @dragend="updateCoordinate"
              @click="mapMarkerOnClick()"
              :draggable="true">
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </b-modal>

    <!-- modal jadwal pengerjaan -->
    <b-modal v-model="modal.isTentukanJadwal" @close="closeModalJadwal">
      <div class="card" style="background-color: #ffffff">
        <header class="card-header">
          <p class="card-header-title"> Tentukan Jadwal Pengerjaan </p>
        </header>
        <div class="card-content">
          <div class="content is-justify-content-center">
            <v-date-picker
              :min-date='minDateOrder'
              @input="onDateRangeChange" value="range" :attributes="dateAttributes"
              :columns="layoutCalender.columns" :rows="layoutCalender.rows" :is-expanded="layoutCalender.isExpanded" is-range />
          </div>
          <div class="content" style="text-align: right;">
            <b-button @click="inputSesi()" style="width: 200px;"
              :type="{'is-light' : days.length === 0, 'is-success' : days.length > 1 }">Simpan</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- pilih sesi pengerjaan -->
    <b-modal v-model="modal.isSesi">
      <div class="card" style="background-color: #ffffff">
        <header class="card-header">
          <p class="card-header-title"> Sesi Pengerjaan </p>
        </header>
        <div class="card-content">
          <div class="content">
            <notification class="is-info">
              <span class="has-text-weight-bold">Pilih salah satu sesi pada tanggal yang telah dipilih!</span>
            </notification>
            <div class="columns is-mobile">
              <div class="column table-container">
                <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                  <thead class="is-title-5">
                    <tr>
                      <td><h5 class="title is-5 is-size-6-mobile">Tanggal Pengerjaan</h5></td>
                      <td><h5 class="title is-5 is-size-6-mobile">Penugasan Tukang & Sesi</h5></td>
                    </tr>
                  </thead>
                  <tbody v-for="(value, index) in filterWorkerSesi" v-bind:key="index">
                    <tr>
                      <td class="is-size-6 has-text-weight-bold">{{ convDate(value.id) }}</td>
                      <td>
                        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                          <tbody>
                            <tr v-for="(nnn, i) in value.detail" :key="'A' +i">
                              <td>{{ nnn.skillsName }}</td>
                              <td>{{ nnn.sesi }}</td>
                              <td>Rp.{{ nnn.price | numFormat }}</td>
                              <td>{{ nnn.start_hour }} - {{ nnn.end_hour }}</td>
                              <td>
                                <!-- <b-checkbox class="is-center" v-bind:value="nnn" v-model.number="sessionSel" :native-value="nnn"></b-checkbox> -->
                                <b-checkbox
                                  v-model="sessionSel"
                                  class="is-center"
                                  :native-value="nnn"
                                  :disabled = "nnn.disabled"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="columns is-mobile m-3">
            <div class="column is-4 is-offset-8">
              <b-button @click="buildSesi()" type="is-primary" style="width: 100%;">Simpan</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Screen Confrim Project -->
    <transition name="fade">
      <div v-show="viewState" class="input-card container is-max-desktop">
        <section>
          <div class="columns is-mobile">
            <div class="column">
              <b-button @click="backCreateProject()" type="is-success" icon-right="arrow-left" />
            </div>
            <div class="column">
              <p class="title is-4 is-size-6-mobile mt-3 has-text-right">Konfirmasi Proyek</p>
            </div>
          </div>
        </section>
        <div class="kang-label"> Client </div>
        <div class="kang-val">
          <h1 class="title is-5 is-size-6-mobile">{{ newProject.project_name }}</h1>
        </div>
        <div class="kang-label"> PIC </div>
        <div class="kang-val">
          <h1 class="title is-5 is-size-6-mobile">{{ newProject.customer_name }}</h1>
        </div>
        <div class="input-card" style="margin-top: 20px;">
          <div style="margin-bottom: 10px;"> Penugasan Tukang </div>
          <div v-if="skillsSelected.length > 0">
            <div v-for="(v, idx) in skillsSelected" :key="'_idxx' + idx" style="display: flex; height: auto; border-bottom: solid thin #f0f0f0; padding: 10px; margin-bottom: 10px;">
              <div style="width: 80px; text-align: center;">
                <img style="width: 30px; height: 30px; border-radius: 5px;" :src="v.image_uri" />
              </div>
              <div style="width: 100%;">
                <p class="title is-6">{{ v.name }}</p>
              </div>
              <!-- <div style="width: 250px;">
                <p class="title is-6">{{ v.tukang }}</p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="kang-label"> Alamat Pengerjaan </div>
        <div class="kang-val">
          <h1 class="title is-5 is-size-6-mobile">{{ newProject.address }}</h1>
          <div v-if="newProject && newProject.jenis_bangunan">
            Tipe Bangunan: <span class="has-text-weight-bold is-size-5 is-size-6-mobile">{{ newProject.jenis_bangunan }}</span>
          </div>
        </div>
        <div v-if="newProject && newProject.address">
          <div style="padding-top: 20px; padding-bottom: 20px;">
            <div v-if="newProject && newProject.latitude && newProject.longitude">
              <GmapMap map-type-id="roadmap" :zoom="15"
                :center="map.currentPlace"
                :options="{disableDefaultUI: true}"
                style="width: 100%; height: 200px;">
                <GmapMarker :position="map.currentPlace">
                </GmapMarker>
              </GmapMap>
            </div>
          </div>
        </div>
        <div class="kang-label"> Deskripsi Pekerjaan</div>
        <div class="kang-val">
          <h1 class="title is-5 is-size-6-mobile">{{ newProject.description }}</h1>
        </div>
        <div class="kang-label">Rincian Biaya</div>
        <div style="margin-top: 10px;" v-if="newProject && newProject.detail_sesi.length > 0">
          <div class="table-container">
            <table class="table">
              <thead>
                <tr>
                  <td class="has-text-weight-bold">Tanggal</td>
                  <td class="has-text-weight-bold">Keahlian</td>
                  <td class="has-text-weight-bold">Sesi</td>
                  <td class="has-text-weight-bold">Harga Sesi</td>
                  <td class="has-text-weight-bold">Total Tukang</td>
                  <td class="has-text-weight-bold">Jumlah</td>
                </tr>
              </thead>
              <!-- dibuat sort by tanggal -->
              <tbody v-if="dataCalculation !== null">
                <tr v-for="(vv, ii) in dataCalculation.details" :key="ii">
                  <td>{{ convDate(vv.date) }}</td>
                  <td>{{ vv.skillsName }}</td>
                  <td>{{ vv.sesi }}</td>
                  <td>Rp.{{ vv.price | numFormat }}</td>
                  <td class="has-text-centered">{{ vv.workerCount }}</td>
                  <td>Rp.{{ (vv.price * vv.workerCount) | numFormat }}</td>
                </tr>
                <tr>
                  <td colspan="5" class="has-text-right has-text-weight-bold">Sub Total</td>
                  <td>Rp.{{ dataCalculation.amount | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="5" class="has-text-right has-text-weight-bold">Pph (2%)</td>
                  <td>Rp.{{ dataCalculation.pph | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="5" class="has-text-right has-text-weight-bold">Total Setelah Pajak</td>
                  <td>Rp.{{ dataCalculation.amount_after_tax | numFormat}}</td>
                </tr>
                <tr>
                  <td colspan="5" class="has-text-right has-text-weight-bold">Biaya Parkir</td>
                  <td>Rp.{{ this.biayaParkir | numFormat }}</td>
                </tr>
                <tr>
                  <td colspan="5" class="has-text-right has-text-weight-bold">Total</td>
                  <td>Rp.{{ dataCalculation.net_amount | numFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="dataCalculation !== null">
          <div class="kang-label"> Total Biaya </div>
          <div style="margin-top: 10px;">
            <div class="title is-5">
              Rp.{{ dataCalculation.net_amount | numFormat }}
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
          <b-button @click.prevent="createProject()" type="is-primary" style="width: 300px;"> Buat Proyek </b-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
// import guard from '../../routerGuard'
import ProjectCustomer from './Customer.vue'
// import PaymentProject from '../pembayaran/Payment.vue'
import ModalBase from '@/components/ModalBase.vue'
import Notification from '@/components/Notification.vue'
import TitleBarProject from '@/components/TitleBarCreateProject'

export default {
  name: 'ProjectInput',
  components: {
    ProjectCustomer,
    // PaymentProject,
    ModalBase,
    Notification,
    TitleBarProject
  },
  data () {
    return {
      sessionSel: [],
      days: [],
      idtransaction_item: null,
      viewState: false,
      customerIsView: false,
      modal: {
        tentukanAlamatIsView: false,
        isTentukanJadwal: false,
        isSesi: false,
        pilihProductIsView: false,
        isMapOnView: false,
        dateColumn: 2,
        isPenugasanTukangIsView: false,
        transactionProducts: [],
        address: '',
        addressRef: '',
        listProductIsView: false,
        isSaldoTidakCukup: false
      },
      fetchProductIsAuto: false,
      productToDo: [],
      productServiceRelated: [],
      productServiceSelected: [],
      pilihProductOnEdit: false,
      pilihProductEditIndex: -1,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      deskripsiPekerjaan: '',
      biayaParkir: '',
      buildingType: [],
      isCustomerNew: false,
      project: null,
      minDateOrder: null,
      skills: [],
      currentSkill: null,
      skillsSelected: [],
      sesiSkills: [],
      dataCalculation: null,
      paymentInfo: null,
      isPph: 0,
      clientSelect: null,
      clientOptions: [],
      searchSkill: ''
    }
  },
  watch: {
    deskripsiPekerjaan (newVal, oldVal) {
      this.$store.commit('project/setDescription', newVal.trim())
    },
    biayaParkir (newVal, oldVal) {
      this.$store.commit('project/setBiayaParkir', newVal.trim())
    },
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    },
    clientSelect (newVal, oldVal) {
      this.$store.commit('project/setIsClient', newVal.id)
      this.$store.commit('project/setProjectName', newVal.name)
    },
    sessionSel: {
      handler: function (newVal, oldVal) {
        const newData = newVal
        const oldData = oldVal

        if (newData !== oldData) {
          oldData.forEach(el => {
            return this.filterWorkerSesi.map(item => {
              item.detail.forEach(detailItem => {
                if (el.date === detailItem.date) {
                  const newItem = newData.find(item => item.sesi_homecare === el.sesi_homecare)

                  if (newItem === undefined) {
                    if (el.skillsName === detailItem.skillsName) {
                      detailItem.disabled = false
                    }
                  }
                }
              })
            })
          })
        }

        newData.forEach(el => {
          return this.filterWorkerSesi.map(item => {
            item.detail.forEach(detailItem => {
              if (el.date === detailItem.date) {
                if (detailItem.sesi_homecare !== el.sesi_homecare && detailItem.skillsName === el.skillsName) {
                  detailItem.disabled = true
                }
              }
            })
          })
        })
      },
      deep: true
    }
  },
  computed: {
    layoutCalender () {
      return this.$screens({
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true
        },
        lg: {
          columns: 2,
          rows: 1,
          isExpanded: true
        }
      })
    },
    titleStack () {
      return [
        'Project',
        'Create Project'
      ]
    },
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl',
      dateSesi: 'project/getSesi',
      newProject: 'project/getNewProject'
    }),
    dates () {
      return this.days.map(day => day.date)
    },
    dateAttributes () {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }))
    },
    filterWorkerSesi () {
      const d = [...new Map(this.listSesi.map(item => [item.id, item])).values()]
      return d
    },
    listSesi () {
      var x = []
      const sesi = []

      if (this.days.length >= 2) {
        const start = moment(this.days[0].date)
        const end = moment(this.days[1].date)

        for (var m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
          const _id = moment(m).format('YYYY-MM-DD')
          x.push({ id: _id, date: m.valueOf() })
        }
        x.forEach((v) => {
          const day = {
            id: v.id,
            selected: [],
            detail: []
          }

          this.skillsSelected.forEach((s) => {
            const _day = moment(v.date).weekday()
            const val = 'Minggu,Senin,Selasa,Rabu,Kamis,Jumat,Sabtu'.split(',')

            s.sesi.forEach((t) => {
              day.detail.push({
                date: v.id,
                sesi_id: t.id,
                skillId: s.id,
                skillsName: s.name,
                sesi: t.name,
                sesi_homecare: t.sesi_homecare,
                start_hour: t.start_hour,
                end_hour: t.end_hour,
                price: t.price,
                workerCount: s.tukang,
                day: val[_day],
                disabled: false
              })
            })
          })
          sesi.push(day)
        })
        return sesi
      } else {
        return []
      }
    },
    totalTukangSelected () {
      let total = 0
      this.productServiceSelected.forEach((row) => {
        row.skills.forEach((_row) => {
          total += _row.order_tukang
        })
      })
      return total
    },
    filteredDataSkill () {
      const search = this.searchSkill.toLowerCase()
      return this.skills.filter(item => typeof item.name === 'string' && item.name.toLowerCase().includes(search))
    }
  },
  methods: {
    closeModalSesiPengerjaan () {
      this.modal.isSesi = false
    },
    closeModalPIC () {
      this.customerIsView = false
    },
    closeModalAlamat () {
      this.modal.tentukanAlamatIsView = false
      this.$store.commit('project/clearAddress')
    },
    closeModalJadwal () {
      this.modal.isTentukanJadwal = false
      this.days = []
    },
    loadSesiSkills () {
      axios.get(this.url + '/api/v1/vendor/sesi?page=&limit=1000')
        .then((data) => {
          this.sesiSkills = data.data.data
        })
    },
    skillIsSelected (val) {
      const sel = JSON.parse(JSON.stringify(val))
      let hasSelected = false
      this.skillsSelected.forEach((rw) => {
        if (rw.id === sel.id) {
          hasSelected = true
        }
      })

      if (hasSelected === false) {
        sel.tukang = 1
        this.skillsSelected.push(sel)
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${sel.name} sudah pernah dipilih`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    deleteSkill (idx) {
      this.skillsSelected.splice(idx, 1)
    },
    loadSkillList () {
      axios.get(this.url + '/api/v1/vendor/skill?page=1&limit=1000')
        .then((cb) => {
          this.skills = cb.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    topupSaldo () {
      this.modal.isSaldoTidakCukup = false
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        const changeIsSelect = idx === _idx ? this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect : this.buildingType[_idx].isSelect = false
        return changeIsSelect
      })
    },
    loadBuildingType () {
      axios.get(this.url + '/api/v1/vendor/building_type')
        .then((cb) => {
          const x = []
          cb.data.data.forEach((rw) => {
            const r = rw
            r.isSelect = false
            x.push(r)
          })
          this.buildingType = x
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadClient () {
      axios.get(this.url + '/api/v1/vendor/client?page=1&limit=1000')
        .then((v) => {
          if (v.data.data.data.length > 0) {
            this.clientOptions = v.data.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    simpanLokasiOnClick () {
      this.modal.isMapOnView = false
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      // this.infoOptions.content = '<div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>'
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
      // this.modal.isMapOnView = false
    },
    editProductOnShow (idx) {
      this.pilihProductOnEdit = true
      this.pilihProductEditIndex = idx
      this.modal.listProductIsView = true
    },
    tentukanJadwalOnShow () {
      this.modal.isTentukanJadwal = true
      this.days = []
      this.sessionSel.splice(0)
    },
    deleteProductList (idx) {
      this.productServiceSelected.splice(idx, 1)
    },
    kembaliKeProyek (event) {
      this.$buefy.dialog.confirm({
        message: `Batalkan pembuatan ${event}`,
        onConfirm: () => this.$router.push({ name: 'ProjectList' })
      })
    },
    productListOnClick (val) {
      if (this.pilihProductOnEdit === false) {
        this.productServiceSelected.push(val)
      } else {
        this.productServiceSelected.splice(this.pilihProductEditIndex, 1)
        this.productServiceSelected.push(val)
      }
      this.modal.listProductIsView = false
      this.pilihProductOnEdit = false
      this.pilihProductEditIndex = -1
      // this.buildServiceRelated()
    },
    productListRemove (idx) {
      this.productToDo.splice(idx, 1)
      // this.buildServiceRelated()
    },
    buildServiceRelated () {
      this.productToDo.forEach((row) => {
        row.worker_primary.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
        row.worker_optional.forEach((wp) => {
          if (!_.find(this.productServiceRelated, { skill_id: wp.skill_id })) {
            const _l = JSON.parse(JSON.stringify(wp))
            _l.order_tukang = 1
            this.productServiceRelated.push(_l)
          }
        })
      })
    },
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    convDateLong (date) {
      return moment(date).format('DD MMMM')
    },
    gettingImageProducts () {
      _.forEach(this.modal.transactionProducts, (v, i) => {
        setTimeout(() => {
          axios.get(v.i_uri)
            .then((cb) => {
              this.modal.transactionProducts[i].image_uri = cb.data.data.url
            })
        }, 500)
      })
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
        // console.info(response.results)
      })
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    pilihLokasiOnClick () {},
    async getAddress (val) {
      await axios.get(this.url + '/api/v1/vendor/map?place=' + val)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.map.address = cb.data.data
          }
        })
    },
    onDateRangeChange (day) {
      this.days = []
      this.days.push({
        id: moment(day.start).format('YYYY-MM-DD'),
        date: moment(day.start).toDate()
      })
      this.days.push({
        id: moment(day.end).format('YYYY-MM-DD'),
        date: moment(day.end).toDate()
      })
    },
    insertCustomer (val) {
      this.$store.commit('project/setCustomer', val)
      this.customerIsView = false
    },
    insertTransactionProduct () {
      const p = []
      this.modal.transactionProducts.forEach((val) => {
        if (val.is_select) {
          p.push(JSON.parse(JSON.stringify(val)))
        }
      })
      const transaction = {
        id: '123123',
        products: p
      }
      this.$store.commit('project/setTransactionProductSelected', transaction)
      this.modal.pilihProductIsView = false
    },
    insertAddress () {
      if (this.modal.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harap detail <b>alamat customer</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      let jenisBangunan = null
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          jenisBangunan = rw.name
          buildingId = rw.id
        }
      })

      const addr = {
        address: this.modal.addressRef + ' + ' + this.modal.address,
        lat: this.map.currentPlace.lat,
        lng: this.map.currentPlace.lng,
        jenis_bangunan: jenisBangunan,
        building_id: buildingId
      }

      if (addr.jenis_bangunan == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Jenis Bangunan Harus Pilih!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('project/setAddress', addr)
      this.modal.tentukanAlamatIsView = false
    },
    inputSesi () {
      this.modal.isTentukanJadwal = false
      this.modal.isSesi = true
    },
    buildSesi () {
      let countSesi = 0
      this.$store.commit('project/clearDetailSesi')

      // validasi jika kosong
      if (!this.sessionSel.length) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 5000,
          message: 'pilihan sesi harus di isi!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.sessionSel.forEach((vals) => {
        if (!_.isEmpty(vals)) {
          countSesi += 1
          const _sel = JSON.parse(JSON.stringify(vals))
          if (vals.sesi !== null) {
            _sel.harga_tukang = 0
          }
          _sel.total_tukang = 0
          this.$store.commit('project/setDetailSesi', vals)
        }
      })

      const x = [...new Set(this.days.map(m => m.id))]
      const y = x.length

      // validasi multiple day dan belum terceklis
      if (countSesi < y) {
        this.$store.commit('project/clearDetailSesi')
        this.$buefy.toast.open({
          duration: 3000,
          message: 'pilihan sesi harus sama dengan tanggal yang dipilih!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.modal.isTentukanJadwal = false
      this.modal.isSesi = false
    },
    setInfoDate () {
      const storeDetails = this.newProject.detail_sesi
      const sortDate = storeDetails.slice().sort((a, b) => new Date(a.date) - new Date(b.date))

      const startDate = sortDate[0].date
      const endDate = sortDate[this.newProject.detail_sesi.length - 1].date
      const duration = moment(endDate).diff(moment(startDate), 'days') + 1

      this.$store.commit('project/setInfoDate', {
        start_date: startDate,
        end_date: endDate,
        duration: duration
      })
    },
    hitungAmount () {
      let amount = 0
      let orderTukang = 0
      let hargaSesi0 = 0
      let hargaSesi1 = 0
      let hargaSesi2 = 0
      let hargaSesi3 = 0
      let feeCharge = 0
      let feeBuilding = 0
      // let totalSesi = 0

      const storeDetails = this.newProject.detail_sesi
      const sortDate = storeDetails.slice().sort((a, b) => new Date(a.date) - new Date(b.date))

      const startDate = sortDate[0].date
      const endDate = sortDate[this.newProject.detail_sesi.length - 1].date
      const dateCount = moment(endDate).diff(moment(startDate), 'days') + 1

      if (dateCount === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Cek sesi terlebih dahulu!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      // total sesi
      // totalSesi = this.newProject.detail_sesi.length

      // total harga perhari
      this.newProject.detail_sesi.forEach((row) => {
        if (row.sesi !== null) {
          if (row.sesi_homecare === 0) {
            hargaSesi0 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 1) {
            hargaSesi1 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 2) {
            hargaSesi2 += (row.workerCount * row.price)
          }
          if (row.sesi_homecare === 3) {
            hargaSesi3 += (row.workerCount * row.price)
          }
        }
      })
      // total tukang
      this.skillsSelected.forEach((row) => {
        orderTukang += row.tukang
      })
      // fee_charge building type
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          feeCharge = rw.fee_charge
        }
      })

      amount = ((hargaSesi0 + hargaSesi1 + hargaSesi2 + hargaSesi3) + ((feeCharge * orderTukang) * dateCount) + parseInt(this.biayaParkir))
      feeBuilding = ((feeCharge * orderTukang) * dateCount)
      return {
        amount: amount,
        orderTukang: orderTukang,
        // startDate: startDate,
        // endDate: endDate,
        dateCount: dateCount,
        fee_building: feeBuilding
      }
    },
    setItem () {
      const x = []
      this.sessionSel.forEach((row) => {
        const y = {
          date: row.date,
          premium_price: 0,
          premium_worker: 0,
          skill_id: row.skillId,
          skill_name: row.skillsName,
          standard_price: row.price,
          session1_price: row.session1_price,
          session2_price: row.session2_price,
          standard_worker: row.workerCount
        }
        x.push(y)
      })

      const vv = [...new Map(x.map(item => [item.skill_id, item])).values()]
      this.$store.commit('project/setItem', vv)
    },
    setSchedule () {
      const skills = []
      const schedules = []
      this.skillsSelected.forEach((row) => {
        skills.push({
          skill_id: row.id,
          skill_name: row.name,
          order_tukang: row.tukang
        })
      })
      this.newProject.detail_sesi.forEach((row) => {
        schedules.push({
          date_sesi: row.date,
          sesi_id: row.sesi_id
        })
      })
      this.$store.commit('project/setSchedule', {
        skills: skills,
        schedules: schedules
      })
    },
    backCreateProject () {
      if (this.newProject === undefined) {
        this.$buefy.snackbar.open({
          message: 'Error Back Project!',
          queue: false
        }, 500)
      } else {
        this.$store.commit('project/clearDetailSesi')
        this.dataCalculation = null
        this.viewState = !this.viewState
      }
    },
    createProjectConfirm () {
      if (this.newProject === undefined) {
        return
      }

      if (this.newProject.detail_sesi.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mohon pilih jadwal dan jadwalkan tukang!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.newProject.customer_name.trim() === '' ||
        this.newProject.jenis_bangunan.trim() === '' ||
        this.newProject.detail_sesi.length === 0 ||
        this.newProject.address.trim() === '' ||
        this.skillsSelected.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mohon lengkapi dulu data yang harus diinput',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      const n = this.hitungAmount()
      this.$store.commit('project/setAmount', {
        amount: n.amount,
        net_amount: n.amount,
        fee_building: n.fee_building
      })
      const vip = this.isPph
      if (vip === true) {
        this.$store.commit('project/setIsPph', 1)
      } else {
        this.$store.commit('project/setIsPph', 0)
      }
      this.setItem()
      // this.setServiceId()
      // this.setProductId()
      this.setSchedule()
      this.setInfoDate()
      this.$store.commit('project/setInfo', {
        idtransaction_item: 0,
        user_id: this.user.id,
        transaction_id: uuidv4()
      })

      if (this.deskripsiPekerjaan.trim() === '') {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Mohon informasi <b>Deskripsi Pekerjaan</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.biayaParkir.trim() === '') {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Mohon informasi <b>Biaya Parkir</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.clientSelect === '') {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Mohon informasi <b>Client</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      axios.post(this.url + '/api/v1/vendor/price_calculation', this.newProject)
        .then((dt) => {
          this.dataCalculation = dt.data.data
          this.viewState = !this.viewState
        })
        .catch((err) => {
          console.log(err.response.data.message)
        })

      // this.viewState = !this.viewState
    },
    createProject () {
      if (this.processing === true) {
        console.log('test async, tolak')
      }

      // set the async state
      this.processing = true

      /** check sisa saldo **/
      axios.post(this.url + '/api/v1/vendor/create_project_deposit', this.newProject)
        .then((res) => {
          this.$buefy.snackbar.open({
            message: 'Create Project Successfully!',
            queue: false
          }, 900)
          this.$router.push({ name: 'Home' })
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: err.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    },
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.modal.isMapOnView = true
    }
  },
  created () {
    this.loadSesiSkills()
    this.loadSkillList()
    this.loadBuildingType()
    // this.newProject = this.setProject()
    this.$store.dispatch('project/setNewProject')
    this.minDateOrder = moment().add(1, 'days').toDate()
    const now = moment()
    if (now.hour() >= 18) {
      this.minDateOrder = moment().add(2, 'days').toDate()
    }
    // guard.check(this.$router, this.$router.currentRoute.path)
  },
  mounted () {
    this.loadClient()
  }
}
</script>

<style lang="scss" scoped>
.dropdown-box {
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 2px;
    div, button {
        width: 100%;
        text-align: left;
    }
}
.input-card {
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.65);
}
.kang-label {
  margin-top: 20px;
}
.kang-val {
  margin-top: 5px;
}
.jenis-bangunan {
  cursor: pointer;
  &:hover {
    background-color: rgb(196, 196, 196);
  }
}
.jenis-bangunan-selected {
  border-radius: 20px;
  background-color: rgb(196, 196, 196);
}
.googleMapsView {
  max-width: 100%;
  height: 200px;
}
.centerImgs {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
</style>
