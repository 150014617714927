<template>
  <div v-if="isLogin">
    <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
      <aside-tools :is-main-menu="true">
        <span slot="label">
          <img src="../assets/logo-kanggo_shell.png" style="width: 80%; margin-top: 0.40rem;"/>
        </span>
      </aside-tools>
      <div class="menu is-menu-main my-6 px-4">
        <template>
          <aside class="menu">
            <p class="menu-label"> Menu </p>
            <ul class="menu-list">
              <li>
                <router-link to="/">
                  <b-icon icon="home" custom-size="default"/>Dashboard
                </router-link>
              </li>
              <li>
                <router-link v-if="!menuAccess('/project/list')" to="/project/list">
                  <b-icon icon="briefcase" custom-size="default"/>Proyek
                </router-link>
              </li>
              <li>
                <router-link v-if="!menuAccess('/laporan')" to="/laporan">
                  <b-icon icon="clipboard-text-outline" custom-size="default"/>Laporan
                </router-link>
              </li>
              <li>
                <router-link v-if="!menuAccess('/pengaturan')" to="/pengaturan">
                  <b-icon icon="settings" custom-size="default"/>Pengaturan
                </router-link>
              </li>
            </ul>
          </aside>
        </template>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AsideTools from '@/components/AsideTools'

export default {
  name: 'AsideMenu',
  components: { AsideTools },
  props: {},
  computed: {
    ...mapState([
      'isAsideVisible'
    ]),
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  },
  methods: {
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    }
  },
  mounted () {}
}
</script>
