<template>
  <card-component title="Change Password" icon="lock">
    <b-field horizontal label="Current password" message="Required. Your current password">
      <b-input name="password_current" type="password" v-model="passwordLama" required autcomplete="current-password" password-reveal/>
    </b-field>
    <hr>
    <b-field horizontal label="New password" message="Required. New password">
      <b-input name="password" type="password" v-model="passwordBaru" required autocomplete="new-password" password-reveal/>
    </b-field>
    <b-field horizontal label="Confirm password" message="Required. New password one more time">
      <b-input name="password_confirmation" type="password" v-model="passwordBaruKonfirm" required autocomplete="new-password" password-reveal/>
    </b-field>
    <hr>
    <b-field horizontal>
      <div class="control">
        <button type="submit" @click="updatePassword()" class="button is-primary" :class="{'is-loading':isLoading}" :disabled="isDisabled"> Submit </button>
      </div>
    </b-field>
  </card-component>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    isDisabled () {
      return !this.passwordLama || !this.passwordBaru || !this.passwordBaruKonfirm
    }
  },
  data () {
    return {
      isLoading: false,
      passwordLama: null,
      passwordBaru: null,
      passwordBaruKonfirm: null
    }
  },
  methods: {
    updatePassword () {
      this.isLoading = true
      if (this.passwordLama === null || this.passwordBaru === null || this.passwordBaruKonfirm === null) {
        return
      }
      const data = {
        user_id: this.user.id,
        old_password: this.passwordLama,
        password: this.passwordBaru,
        confirm_pass: this.passwordBaruKonfirm
      }
      axios.put(this.url + '/api/v1/vendor/user/update-password', data)
        .then((cb) => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: 'Update password berhasil!...',
            queue: false
          }, 800)
          this.passwordLama = null
          this.passwordBaru = null
          this.passwordBaruKonfirm = null

          this.$store.dispatch('auth/logout')
          this.$router.push({ name: 'Login' })
        })
        .catch((err) => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: err.response.data.message,
            queue: false
          }, 900)
        })
    }
  }
}
</script>
