<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <notification class="is-info" v-if="!menuAccess('/project/input')">
        Create Project click this button
        <button @click="btnTambahProyekTukangLangsungOnClick()" slot="right" class="button is-white is-small">
          <b-icon icon="plus" custom-size="default" />
          <span>Pesan Tukang Langsung</span>
        </button>
      </notification>
      <tiles>
        <card-widget class="tile is-child" type="is-primary" icon="account-clock" :number="this.inPlanning" label="Proyek Dalam Rencana"/>
        <card-widget class="tile is-child" type="is-info" icon="account-multiple-check" :number="this.active" label="Proyek Aktif"/>
        <card-widget class="tile is-child" type="is-success" icon="check-bold" :number="this.completed" label="Proyek Complete"/>
      </tiles>

      <card-component title="Project Mencari Mitra" class="has-table has-mobile-sort-spaced">
        <project-table-mitra/>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import TitleBar from '@/components/TitleBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import Notification from '@/components/Notification.vue'
import ProjectTableMitra from '@/components/ProjectTableMitra'

export default {
  name: 'Home',
  components: {
    ProjectTableMitra,
    CardComponent,
    CardWidget,
    Tiles,
    TitleBar,
    Notification
  },
  data () {
    return {
      inPlanning: 0,
      active: 0,
      completed: 0,
      isLoading: false
    }
  },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Dashboard'
      ]
    },
    ...mapGetters({
      url: 'setup/getUrl',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user'
    })
  },
  mounted () {
    if (this.url) {
      this.isLoading = true
      const urlCountProyek = this.url + '/api/v1/vendor/transaction_summary'
      axios.get(urlCountProyek).then((r) => {
        this.isLoading = false
        if (r.data.data) {
          this.inPlanning = r.data.data.inplanning
          this.active = r.data.data.active
          this.completed = r.data.data.completed
        }
      }).catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
    }

    const rs = localStorage.getItem('isSign')
    const rld = localStorage.getItem('reloaded')
    const v = JSON.parse(rs)
    if (v === 1) {
      this.$buefy.snackbar.open({
        message: `Welcome back ${this.user.name}`,
        queue: false
      })
      localStorage.removeItem('isSign')
    } else {
      localStorage.removeItem('isSign')
    }

    const vl = JSON.parse(rld)
    if (vl === 0) {
      location.reload()
      localStorage.removeItem('reloaded')
    }
  },
  methods: {
    roleAccess (role) {
      if (this.roles === null) { return true }

      let roleCheck = false
      if (this.roles !== role) {
        roleCheck = true
      }
      return roleCheck
    },
    menuAccess (path) {
      if (this.roles === null) {
        return true
      }
      let check = true
      this.rolePath.forEach((row) => {
        if (row.controller_path === path) {
          check = false
        }
      })
      return check
    },
    btnTambahProyekTukangLangsungOnClick () {
      this.$router.push('/project/input-tukang-langsung')
    }
  }
}
</script>
