/* eslint-disable */

const _members = {
  "code": 1,
  "message": "daftar member",
  "data": {
      "total_member": 4,
      "total_page": 2,
      "current_page": 1,
      "members": [
          {
              "name": "Galih sudjono",
              "email": "galihsudjono@gmail.com",
              "role": "General Manager Operations",
              "branch": "Teras Kota - Serpong"
          },
          {
              "name": "Michael Putong",
              "email": "michael@gmail.com",
              "role": "Store Manager",
              "branch": "Teras Kota - Serpong"
          }
      ]
  }
}

const _roles = {
  "code": 1,
  "message": "jumlah member per role",
  "data": [
      {
          "role": "General Manager Operations",
          "total": 1
      },
      {
          "role": "Accountant",
          "total": 1
      },
      {
          "role": "Store Manager",
          "total": 1
      },
      {
          "role": "CS Admin",
          "total": 1
      }
  ]
}

export default {
  getMembers (cb) {
    setTimeout(() => cb(_members.data), 100)
  },
  getRoles (cb) {
    setTimeout(() => cb(_roles.data), 100)
  }
}