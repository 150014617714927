<template>
  <div>
    <b-table
      :checked-rows.sync="checkedRows"
      :striped="true"
      :hoverable="true"
      :total="total"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :data="dataListTrans"
      default-sort="id"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-pagination>

      <b-table-column label="#Id" field="id" v-slot="props">
        <small class="has-text-gray is-abbr-like" :title="props.row.id"> {{ props.row.id }} </small>
      </b-table-column>
      <b-table-column label="#Transaction ID" field="transaction_id" sortable v-slot="props">
        <small class="has-text-weight-bold is-abbr-like" :title="props.row.transaction_id">{{ props.row.transaction_id }}</small>
      </b-table-column>
      <b-table-column label="Client" field="client_name" v-slot="props">{{ props.row.company_name }}</b-table-column>
      <b-table-column label="Address" field="address" sortable v-slot="props">
        <small class="has-text-grey is-abbr-like" :title="props.row.address">{{ props.row.address }}</small>
      </b-table-column>
      <b-table-column label="Amount" field="amount" v-slot="props">Rp.{{ props.row.amount | numFormat }}</b-table-column>
      <b-table-column label="Payment Status" field="payment_status" v-slot="props">
        <span :class="{'tag is-danger': props.row.payment_status === 'EXPIRED', 'tag is-info': props.row.payment_status === 'INVOICE'}">{{ props.row.payment_status }}</span>
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="account-alert-outline" size="is-large"/>
            </p>
            <p>No data here, Select date first&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ListTransaksi',
  props: { date: Object },
  data () {
    return {
      filterTanggal: null,
      isLoading: false,
      page: 1,
      perPage: 10,
      total: 0,
      dataListTrans: [],
      paginated: false,
      checkedRows: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  watch: {
    date (newVal) {
      if (newVal !== null) {
        this.getData(newVal, 1)
      }
    }
  },
  methods: {
    convDate (val) {
      return moment(val).format('DD MMMM YYYY hh:mm')
    },
    onPageChange (page) {
      this.getData(this.date, page)
    },
    getData (val, page) {
      this.isLoading = true
      this.page = page
      const pr = '/api/v1/vendor/transaction_history?start_date=' + val.tgl1 + '&end_date=' + val.tgl2 + '&limit=' + this.perPage + '&page=' + this.page
      axios.get(this.url + pr)
        .then((cb) => {
          this.isLoading = false
          if (cb) {
            this.paginated = true
            this.dataListTrans = cb.data.data.data
            this.total = (cb.data.data.total_page * this.perPage)
            this.page = cb.data.data.current_page
          }
        })
    },
    loadDataFirst (page) {
      this.isLoading = true
      let tgl1 = null
      let tgl2 = null
      if (this.filterTanggal === null) {
        tgl1 = moment().startOf('month').format('YYYY-MM-DD')
        tgl2 = moment().endOf('month').format('YYYY-MM-DD')
      }
      const pr = '/api/v1/vendor/transaction_history?start_date=' + tgl1 + '&end_date=' + tgl2 + '&limit=10&page=' + page
      axios.get(this.url + pr)
        .then((cb) => {
          this.isLoading = false
          if (cb) {
            this.paginated = true
            this.dataListTrans = cb.data.data.data
            this.total = (cb.data.data.total_page * this.perPage)
            this.page = cb.data.data.current_page
          }
        })
    }
  },
  created () {}
}
</script>
