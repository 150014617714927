<template>
  <div class="is-clipped">
    <!-- PDF Content Here -->
    <div class="columns pdf" ref="document">
      <div class="column is-10 is-offset-1">
        <div class="columns">
          <div class="column">
            <div class="level">
              <div class="level-left">
                <div class="column is-one-quarter">
                  <img src="../../assets/logo-kanggo.png" class="image" style="width: 10%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-bold has-text-centered is-size-4">
              <strong><u>INVOICE SUMMARY</u></strong>
            </p>
          </div>
        </div>
        <div class="columns" v-if="detailsInv !== null">
          <div class="column">
            <div class="columns is-desktop is-mobile mb-5">
              <div class="column" style="font-size: 0.875rem;">
                <p>Kepada: <br>
                  <span><strong> {{ detailsInv.client.name }} </strong></span> <br>
                  <span>{{ detailsInv.client.address }}</span> <br>
                  <span>{{ detailsInv.client.phone }}</span> <br>
                  <span>{{ detailsInv.client.email }}</span>
                </p>
              </div>
              <div class="column" style="font-size: 0.875rem;">
                <span class="has-text-weight-bold">No.Reference : {{ detailsInv.invoice }}</span> <br>
                <span>Tanggal Invoice :  {{ convDate(detailsInv.created_at) }}</span> <br>
              </div>
            </div>

            <table class="table is-bordered is-fullwidth mt-6 mb-6">
              <tr class="has-text-weight-bold has-text-centered">
                <th class="has-text-left">No.Invoice</th>
                <th>Jumlah Tukang</th>
                <th>Total Harga Tukang</th>
                <th>Biaya Parkir</th>
                <th>Total Harga</th>
              </tr>
              <tbody>
                <tr v-for="(v, index) in detailsInv.detail" :key="index">
                  <td class="content is-normal"> {{ v.invoice}} </td>
                  <td class="has-text-centered"> {{ v.worker_assigned }} </td>
                  <td class="has-text-centered"> Rp.{{ v.price | numFormat }} </td>
                  <td class="has-text-centered">Rp.{{ parseInt(v.parkir_fee) | numFormat }}</td>
                  <td class="has-text-centered">Rp.{{ parseInt(v.sub_total) | numFormat }}</td>
                </tr>
              </tbody>
              <tr>
                <td colspan="4" class="has-text-right"> <span> Sub Total </span> </td>
                <td class="has-text-centered">Rp.{{ (parseInt(detailsInv.sub_total)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="4" class="has-text-right"> <span> Pph (2%) </span> </td>
                <td class="has-text-centered">(-) Rp.{{ (parseInt(detailsInv.pph)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="4" class="has-text-right">
                  <span><strong> Net Total </strong></span>
                </td>
                <td class="has-text-centered">
                  <strong>Rp.{{ detailsInv.net_amount | numFormat }}</strong>
                </td>
              </tr>
            </table>

            <div class="columns is-mobile mt-1" style="font-size: 0.875rem;">
              <div class="column">
                <p>
                  <span> Pembayaran dapat ditransfer ke rekening: </span> <br>
                  <span> <strong> PT. Tenaga Kanggo Indonesia </strong> </span> <br>
                  <span> BCA KCU Serpong </span> <br>
                  <span> No rekening: <strong> 4978888397 </strong> </span>
                </p>
              </div>
              <div class="column has-text-right">
                <p><strong>Tangerang</strong>, {{ convDate(new Date()) }}</p>
                <p style="margin-top: 5rem;">
                  <span> <strong> Florencia Nita </strong> </span> <br>
                  <span> Finance </span> <br>
                  <span> E: nita@kanggo.id </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" style="background-color: #ffffff;">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                <b-button type="is-primary" @click="exportPDF()">Export PDF</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'

export default {
  name: 'InvoicePDFSummary',
  props: ['invoice'],
  data () {
    return {
      detailsInv: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    netAmountPerDay () {
      let amountPerDay = 0
      let amountPD = 0
      let totalWorker = 0
      this.detailsProject.schedule.forEach((row, index) => {
        if (row.date_sesi) {
          row.detail_sesi.forEach((rows, idx) => {
            amountPD = rows.price
            totalWorker = rows.dataWorker.total_worker
          })
        }
      })
      amountPerDay = amountPD * totalWorker
      return amountPerDay
    },
    totalDay () {
      const day = []
      this.detailsProject.schedule.forEach((row) => {
        day.push({
          date: row.date_sesi
        })
      })

      const totalDay = day.length
      return totalDay
    },
    totalTukang () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })
      return totalTukang
    },
    totalSesi () {
      let x = 0
      this.detailsProject.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          x += (result.price * result.dataWorker.total_worker)
        })
      })
      return x
    },
    amountAfterTax () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })

      const amountProject = this.detailsProject.amount
      const afterPajak = (amountProject) - parseInt(this.detailsProject.fee_charge_building) * parseInt(totalTukang)
      return afterPajak
    },
    addressFIlter () {
      const myAddress = this.detailsProject.address
      const myArray = myAddress.split('+')

      return myArray.toString()
    }
  },
  components: {},
  methods: {
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    exportPDF () {
      const noInvoices = this.detailsInv.invoice
      html2pdf(this.$refs.document, {
        margin: 0,
        filename: `${noInvoices}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        enableLinks: false,
        html2canvas: { dpi: 192, scale: 2, useCORS: true, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', floatPrecision: 'smart' }
      })
    },
    async getDetails () {
      await axios.get(`${this.url}/api/v1/vendor/invoices/${this.invoice}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.detailsInv = cb.data.data
          }
        })
    }
  },
  created () {},
  mounted () { this.getDetails() }
}
</script>

<style lang="scss" scoped>

table,
tr, td, th {
  border: solid 1px lightgray;
}
.pdf {
  background-color: white;
  align-items: center;
  font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
  font-size: 12px;
}

.watermarkCancel {
  background:url("../../assets/Dibatalkan.png") center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermarkPaid {
  background:url("../../assets/Lunas.png") center center no-repeat;
  top: 2rem;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
