/* eslint-disable */
const state = () => ({
  list: {}
})

const getters = {
  getSkill: (state, getters, rootState) => {
    return state.list
  }
}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
