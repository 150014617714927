import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/login/Index.vue'
import ProjectList from '../views/project/List.vue'
// import ProjectDetail from '../views/project/Detail.vue'
import ProjectInput from '../views/project/Input.vue'
import PembayaranList from '../views/pembayaran/List.vue'
import Product from '../views/product/Index.vue'
import Pengaturan from '../views/pengaturan/Index.vue'
import UserList from '../views/user/List.vue'
import Laporan from '../views/laporan/Index.vue'
import UbahPassword from '../views/user/UbahPassword.vue'
import PendingTopUp from '../views/laporan/PendingTopUp.vue'
import ApprovalTopUp from '../views/top-up/ApprovalTopup.vue'
import BeforeAfterProject from '../views/project/BeforeAfter.vue'
import Profile from '../views/Profile.vue'
import PengaturanProductDetail from '../views/pengaturan/ProductDetail.vue'
import ProjectInputTukangLangsung from '../views/project/InputTukangLangsung.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '../views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Login.vue')
      }
    ]
  },
  {
    path: '/project/list',
    name: 'ProjectList',
    component: ProjectList
  },
  {
    path: '/project/detail/:projectId/:source',
    name: 'ProjectDetail',
    component: () => import(/* webpackChunkName: "ProjectDetail" */ '../views/project/Detail.vue')
  },
  {
    path: '/project/input',
    name: 'ProjectInput',
    component: ProjectInput
  },
  {
    path: '/project/input-tukang-langsung',
    name: 'ProjectInputTukangLangsung',
    component: ProjectInputTukangLangsung
  },
  {
    path: '/pembayaran/list',
    name: 'PembayaranList',
    component: PembayaranList
  },
  {
    path: '/user/list',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan
  },
  {
    path: '/ubah-password',
    name: 'UbahPassword',
    component: UbahPassword
  },
  {
    path: '/pengaturan',
    name: 'Pengaturan',
    component: Pengaturan
  },
  {
    path: '/pengaturan/product-detail/:productId',
    name: 'PengaturanProductDetail',
    component: PengaturanProductDetail
  },
  {
    path: '/pending-top-up',
    name: 'PendingTopUp',
    component: PendingTopUp
  },
  {
    path: '/approval-top-up',
    name: 'ApprovalTopUp',
    component: ApprovalTopUp
  },
  {
    path: '/project/before-after/:projectId',
    name: 'BeforeAfterProject',
    component: BeforeAfterProject
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: Profile
  }
]

const router = new VueRouter({
  routes,
  linkExactActiveClass: 'is-active'
})
router.beforeEach((to, from, next) => {
  // next()
  const rs = localStorage.getItem('webapp_b2b_imajihaus')
  const auth = JSON.parse(rs)
  if (to.name !== 'Login' && (!auth || !auth.token)) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
