<template>
  <div class="is-clipped">
    <!-- PDF Content Here -->
    <div class="columns pdf" ref="document">
      <div :class="{'watermarkCancel': this.statusProject === 'Cancelled'}"></div>
      <div class="column is-10 is-offset-1">
        <div class="columns">
          <div class="column">
            <div class="level">
              <div class="level-left">
                <div class="column is-one-quarter">
                  <img src="../../assets/logo-kanggo.png" class="image" style="width: 10%"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-bold has-text-centered is-size-4">
              <strong><u>INVOICE</u></strong>
            </p>
          </div>
        </div>
        <div class="columns" v-if="detailsProject !== null">
          <div class="column">

            <div class="columns is-desktop is-mobile mb-5">
              <div class="column" style="font-size: 0.875rem;">
                <p>Kepada: <br>
                  <span class="has-text-weight-bold">{{ detailsProject.company_name }}</span> <br>
                  <span>{{ detailsProject.company_address }}</span> <br>
                  <span>{{ detailsProject.company_phone }}</span> <br>
                </p>
              </div>
              <div class="column" style="font-size: 0.875rem;">
                <span>Invoice Number:
                  <span class="has-text-weight-bold">{{ detailsProject.invoice }}</span>
                </span> <br>
                <span>Tanggal Invoice:  {{ convDate(detailsProject.created_at) }}</span> <br>
                <span>ID Proyek: #{{ detailsProject.id }}</span> <br>
                <span>PIC: {{ detailsProject.consumer_name }}</span> <br>
                <span>Periode: {{ convDate(detailsProject.start_date) }} – {{ convDate(detailsProject.end_date) }}</span> <br>
              </div>
            </div>

            <table class="table is-bordered is-fullwidth">
              <tr class="has-text-weight-bold has-text-centered">
                <th>Jenis Tukang</th>
                <th>Jumlah Tukang</th>
                <th>Harga Pertukang</th>
                <th>Total Harga</th>
              </tr>
              <tbody v-for="(v, index) in detailsProject.schedule" :key="index">
                <tr>
                  <td class="has-text-weight-bold" colspan="5">{{ convDate(v.date_sesi) }}</td>
                </tr>
                <tr v-for="(vv, idx) in v.detail_sesi" :key="idx">
                  <td class="content is-normal">
                    <ul class="is-lower-alpha">
                      <li>{{ vv.name }} - {{ vv.dataWorker.name }}</li>
                    </ul>
                  </td>
                  <td class="has-text-centered"> {{ vv.dataWorker.worker_assigned }} </td>
                  <td class="has-text-centered"> Rp.{{ vv.price | numFormat }} </td>
                  <td class="has-text-centered">Rp.{{ parseInt(vv.dataWorker.worker_assigned) * parseInt(vv.price) | numFormat }}</td>
                </tr>
                <!-- <tr>
                  <td>Net Amount</td>
                  <td>{{ netAmountPerDay }}</td>
                </tr> -->
              </tbody>
              <tr>
                <td colspan="3" class="has-text-right"> <span> Sub Total </span> </td>
                <td class="has-text-centered">Rp.{{ (parseInt(detailsProject.paid_amount) - parseInt(detailsProject.fee_charge_building) + +detailsProject.pph) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="3" class="has-text-right"> <span> Pph (2%) </span> </td>
                <td class="has-text-centered">(-) Rp.{{ (parseInt(detailsProject.pph)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="3" class="has-text-right"> <span> Total Setelah Pph </span> </td>
                <td class="has-text-centered">Rp.{{ (parseInt(detailsProject.paid_amount - detailsProject.fee_charge_building)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="3" class="has-text-right"> <span> Biaya Parkir </span> </td>
                <td class="has-text-centered">(+) Rp.{{ (parseInt(detailsProject.fee_charge_building)) | numFormat }}</td>
              </tr>
              <tr>
                <td colspan="3" class="has-text-right">
                  <span><strong> Net Total </strong></span>
                </td>
                <td class="has-text-centered">
                  <strong>Rp.{{ detailsProject.paid_amount | numFormat }}</strong>
                </td>
              </tr>
            </table>
            <div class="columns is-mobile mt-1">
              <div class="column">
                <p>
                  <span> Pembayaran dapat ditransfer ke rekening: </span> <br>
                  <span> <strong> PT. Tenaga Kanggo Indonesia </strong> </span> <br>
                  <span> BCA KCU Serpong </span> <br>
                  <span> No rekening: <strong> 4978888397 </strong> </span>
                </p>
              </div>
              <div class="column has-text-right">
                <p class="has-text-weight-bold">Tangerang, {{ convDate(new Date()) }}</p>
                <p style="margin-top: 5rem;">
                  <span> <strong> Florencia Nita </strong> </span> <br>
                  <span> Finance </span> <br>
                  <span> E: nita@kanggo.id </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" style="background-color: #ffffff;">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                <b-button type="is-primary" @click="exportPDF()">Export PDF</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'

export default {
  name: 'InvoicePDF',
  props: ['project'],
  data () {
    return {
      noInvoices: null,
      detailsProject: null,
      statusProject: null,
      year: new Date().getFullYear()
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    netAmountPerDay () {
      let amountPerDay = 0
      let amountPD = 0
      let totalWorker = 0
      this.detailsProject.schedule.forEach((row, index) => {
        if (row.date_sesi) {
          row.detail_sesi.forEach((rows, idx) => {
            amountPD = rows.price
            totalWorker = rows.dataWorker.total_worker
          })
        }
      })
      amountPerDay = amountPD * totalWorker
      return amountPerDay
    },
    totalTukang () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })
      return totalTukang
    },
    totalSesi () {
      let x = 0
      this.detailsProject.schedule.forEach((row) => {
        row.detail_sesi.forEach((result) => {
          x += (result.price * result.dataWorker.total_worker)
        })
      })
      return x
    },
    amountAfterTax () {
      let totalTukang = 0
      this.detailsProject.schedule.forEach((row, index) => {
        row.detail_sesi.forEach((rows, idx) => {
          totalTukang += rows.dataWorker.total_worker
        })
      })

      const amountProject = this.detailsProject.amount
      const afterPajak = (amountProject) - parseInt(this.detailsProject.fee_charge_building) * parseInt(totalTukang)
      return afterPajak
    }
  },
  components: {},
  methods: {
    convDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    exportPDF () {
      html2pdf(this.$refs.document, {
        margin: 0,
        filename: `${this.noInvoices}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        enableLinks: false,
        html2canvas: { dpi: 192, scale: 2, useCORS: true, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', floatPrecision: 'smart' }
      })
    },
    async getDetails () {
      await axios.get(`${this.url}/api/v1/vendor/project_detail_sesi?project_id=${this.project}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.detailsProject = cb.data.data[0]
            this.statusProject = cb.data.data[0].project_status
            this.noInvoices = cb.data.data[0].invoice
          }
        })
    }
  },
  created () {
    this.getDetails()
  }
}
</script>

<style lang="scss" scoped>
table,
tr, td, th {
  border: solid 1px lightgray;
}
.pdf {
  background-color: white;
  align-items: center;
  font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
  font-size: 12px;
}

.watermarkCancel {
  background:url("../../assets/Dibatalkan.png") center center no-repeat;
  top: 0rem;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
}

.watermarkPaid {
  background:url("../../assets/Lunas.png") center center no-repeat;
  top: 2rem;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
